import React, { useState } from 'react';
import { BaseTheme } from '../../theme/baseTheme';
import { SxProp, useDripsyTheme } from 'dripsy';
import { Button } from '../Button/legacy/Button';
import styles from './ButtonGroup.module.scss';

export interface ButtonProps {
  label: string;
  onClick?: () => void;
}

interface Props {
  variant?: keyof BaseTheme['buttons'];
  buttonProps: ButtonProps[];
  // if a parent component wants to manage the active state themselves, they can define this
  managedActiveIdx?: number | null;
  // don't want to manage, but do want a default to already be active
  initialActiveIdx?: number;
}
export const ButtonGroup = ({
  variant = 'secondary',
  buttonProps,
  managedActiveIdx,
  initialActiveIdx,
}: Props) => {
  const [activeIdx, setActiveIdx] = useState<undefined | number>(
    managedActiveIdx || initialActiveIdx
  );

  const { theme } = useDripsyTheme();
  const buttonTheme = theme.buttons as BaseTheme['buttons'];
  const variantSx = buttonTheme[variant];

  const buttons = buttonProps.map((props, idx) => {
    const borderClassName =
      idx === 0
        ? styles.firstButton
        : idx === buttonProps.length - 1
        ? styles.lastButton
        : styles.middleButton;

    const onButtonClick = () => {
      if (props.onClick) {
        props.onClick();
      }
      setActiveIdx(idx);
    };

    const isActive =
      managedActiveIdx !== undefined
        ? idx === managedActiveIdx
        : idx === activeIdx;
    const activeSx: SxProp = isActive ? variantSx.$active : {};

    return (
      <Button
        key={props.label}
        variant={variant}
        label={props.label}
        onClick={onButtonClick}
        className={borderClassName}
        sx={{ ...activeSx, minWidth: 'fit-content', flex: 1 }}
      />
    );
  });

  return <div className={styles.buttonGroup}>{buttons}</div>;
};
