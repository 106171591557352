import React from 'react';
import { EventStatusesEnum } from '../../../state/events/types';
import StatusBadge from '../../../aurora/components/StatusBadge/StatusBadge';

interface Props {
  status: EventStatusesEnum;
  onClick?: () => void;
}
const EventStatusLabel = ({ status, onClick }: Props) => {
  const label = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  return (
    <StatusBadge
      content={label}
      status={label === 'New' ? 'blue' : 'gray'}
      onClick={onClick}
    />
  );
};

export default EventStatusLabel;
