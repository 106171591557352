import { JourneyEntity, JourneyStatus } from '../state/journeys/types';
import { ShipmentEntity, ShipmentTypeEnum } from '../state/shipments/types';

export const getSelectedLegIndex = (
  journey: JourneyEntity,
  shipments: ShipmentEntity[] | undefined
): number => {
  if (shipments === undefined || shipments.length === 0) {
    return 0;
  }

  const defaultIndex = Math.max(journey.currentLeg - 1, 0);

  if (journey.status === JourneyStatus.COMPLETE) {
    const regulatedShipment = shipments.find(
      (shipment) => shipment.shipmentType === ShipmentTypeEnum.REGULATED
    );
    return regulatedShipment
      ? shipments.indexOf(regulatedShipment)
      : defaultIndex;
  }

  return defaultIndex;
};
