import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../aurora/components/Button/legacy/Button';
import { isArtyc, selectAuth } from '../../../../../state/auth';
import { CustomerCostReportEntity } from '../../../../../state/customerCostReports/types';
import SaveButton from '../form/SaveButton';
import styles from './Report.module.scss';
import { usePDF } from 'react-to-pdf';
import CostReport from './CostReport';

interface Props {
  report: CustomerCostReportEntity;
  printVersion?: boolean;
}
const ActionBar = ({ report, printVersion }: Props) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const [exportReport, setExportReport] = useState(false);

  const goHome = () => navigate('/portal/customer-cost-reports');
  const goEstimate = () => navigate(`/portal/cost-calculator/${report._id}`);

  const companyName = report.companyName.replace(' ', '-');
  const { toPDF, targetRef } = usePDF({
    filename: `${companyName}_report.pdf`,
  });

  // temporarily render the component and export to PDF
  const onExport = () => {
    setExportReport(true);
    setTimeout(() => {
      const { current } = targetRef;
      if (current) {
        toPDF();
        setExportReport(false);
      }
    }, 200);
  };

  if (!isArtyc(auth) || printVersion) {
    return null;
  }

  return (
    <div className={styles.actionBar}>
      <div className={styles.subBar}>
        <Button label="Return Home" variant="secondary" onClick={goHome} />
        <Button
          label="Return to Estimate"
          variant="secondary"
          onClick={goEstimate}
        />
      </div>
      <div className={styles.subBar}>
        <SaveButton variant="secondary" label="Save" onSuccess={() => {}} />
        <SaveButton
          variant="primary"
          label="Save and Export"
          onSuccess={() => onExport()}
        />
      </div>
      {exportReport && (
        <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
          <CostReport report={report} printVersion ref={targetRef} />
        </div>
      )}
    </div>
  );
};

export default ActionBar;
