import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EntityState, DataPayload } from '../types';
import { AddressEntity } from './types';

export interface AddressState extends EntityState<AddressEntity> {}

const initialState: AddressState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'addressLine1',
  sortDirection: -1,
};

const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses: (
      state,
      { payload }: PayloadAction<DataPayload<AddressEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
  },
});

export const { setAddresses } = addressSlice.actions;

export default addressSlice.reducer;
