import React, { ComponentType, MouseEventHandler, useRef } from 'react';
import { Pressable, Text, useDripsyTheme, SxProp, useSx } from 'dripsy';
import { Link } from 'react-router-dom';
import { BaseTheme } from '../../../theme/baseTheme';
import { IconProps } from '../../../icons/IconBase';
import { useHover } from 'react-native-web-hooks';
import { IconLoading } from '../../../icons';
import styles from './Button.module.scss';

interface BaseButtonProps {
  variant: keyof BaseTheme['buttons'];
  label?: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  disabled?: boolean;
  round?: boolean;
  sx?: SxProp;
  onClickIcon?: () => void;
  disableHover?: boolean;
  loading?: boolean;
  className?: string;
}

interface ClickButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  href?: never;
}
interface LinkButtonProps {
  href: string;
  onClick?: never;
}
// must either have onClick or href but not both
export type ButtonActionProps = ClickButtonProps | LinkButtonProps;

type Props = ButtonActionProps & BaseButtonProps;

export const Button = ({
  variant,
  label,
  iconLeft,
  iconRight,
  disabled,
  round,
  onClick,
  href,
  sx,
  onClickIcon,
  disableHover,
  loading,
  className,
}: Props) => {
  const { theme } = useDripsyTheme();
  const buttons = theme.buttons as BaseTheme['buttons'];
  const variantSx = buttons[variant];
  const sxProp = useSx();

  const isDisabled = disabled || loading;
  const buttonRef = useRef(null);
  const isHovered = useHover(buttonRef);
  const renderHovered = !isDisabled && isHovered && !disableHover;
  // if disabled from loading, we want to keep original colors
  const textColor = disabled
    ? 'gray300'
    : renderHovered
    ? variantSx.$hover.color
    : variantSx.color;

  const renderIcon = (Icon: ComponentType<IconProps>) => {
    const icon = <Icon width={16} height={16} color={textColor} />;
    return onClickIcon === undefined ? (
      icon
    ) : (
      <Pressable onPress={onClickIcon} disabled={isDisabled}>
        {icon}
      </Pressable>
    );
  };

  const buttonClasses = `${styles.button} ${round ? styles.round : ''} ${
    disabled ? styles.disabled : ''
  } ${loading ? styles.loading : ''} ${className}`;

  const sxStyles = sxProp({
    ...(!disabled && variantSx),
    ...(renderHovered && variantSx.$hover),
    ...sx,
  });

  const ButtonContent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        ...sxProp({ color: textColor }),
      }}
    >
      {iconLeft ? renderIcon(iconLeft) : null}
      {loading ? (
        <IconLoading width={16} height={16} color={textColor} />
      ) : label ? (
        <Text
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: 'inherit',
          }}
        >
          {label}
        </Text>
      ) : null}
      {iconRight ? renderIcon(iconRight) : null}
    </div>
  );

  return href ? (
    <Link to={href} ref={buttonRef} className={buttonClasses} style={sxStyles}>
      <ButtonContent />
    </Link>
  ) : (
    <button
      ref={buttonRef}
      className={buttonClasses}
      onClick={onClick}
      disabled={isDisabled}
      style={sxStyles}
    >
      <ButtonContent />
    </button>
  );
};
