import React, { ComponentType, useEffect, useState } from 'react';
import {
  SegmentEntity,
  SegmentSnapshotEntity,
} from '../../../../state/segments/types';
import {
  Accordion,
  AccordionSection,
} from '../../../../aurora/components/Accordion/Accordion';
import {
  IconChartArrow,
  IconSnow,
  IconWarning,
} from '../../../../aurora/icons';
import TempManagementSection from '../../../journeys/components/journeyPage/leftColumn/TempManagementSection';
import DeviceDataSection from './DeviceDataSection';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { useSelector } from 'react-redux';
import SegmentsApi from '../../../../api/segmentsApi';
import { Text } from '../../../../aurora/typography/Text/Text';
import { EventEntity } from '../../../../state/events/types';
import EventsApi from '../../../../api/eventsApi';
import { IconProps } from '../../../../aurora/icons/IconBase';
import EventsSection from './EventsSection';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import SlideoutFooter from './SlideoutFooter';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';

const mkHeader = (title: string, Icon: ComponentType<IconProps>) => (
  <div style={{ display: 'flex', gap: '8px' }}>
    <Icon height={20} width={20} />
    {title}
  </div>
);

interface Props {
  segment: SegmentEntity | undefined;
  showGraph: () => void;
  selectedEventId?: string;
  open: boolean;
  closeSlideout: () => void;
  isArtycViewer: boolean;
}
const EventSlideout = ({
  segment,
  showGraph,
  selectedEventId,
  open,
  closeSlideout,
  isArtycViewer,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [snapshots, setSnapshots] = useState<SegmentSnapshotEntity[]>([]);
  const [events, setEvents] = useState<EventEntity[]>([]);
  const [shippingProfile, setShippingProfile] = useState<
    ShippingProfileEntity | undefined
  >(undefined);

  const loadData = async () => {
    if (segment !== undefined) {
      try {
        setLoading(true);
        setError(false);

        const shippingProfileResp =
          segment.shippingProfile !== undefined
            ? await ShippingProfilesApi.getShippingProfile(
                axiosPrivate,
                auth,
                segment.shippingProfile
              )
            : undefined;
        setShippingProfile(shippingProfileResp);

        const snapshotResp = await SegmentsApi.getSegmentSnapshots(
          axiosPrivate,
          auth,
          isArtycViewer,
          segment._id
        );
        setSnapshots(snapshotResp);

        const eventResp = await EventsApi.getEventsForSegment(
          axiosPrivate,
          auth,
          segment._id
        );
        setEvents(eventResp);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [segment]);

  const sections: AccordionSection[] = [
    {
      header: mkHeader('Temperature Management', IconSnow),
      body: (
        <TempManagementSection
          segment={segment}
          shippingProfile={shippingProfile}
        />
      ),
    },
    {
      header: mkHeader('Shipper Data', IconChartArrow),
      body: <DeviceDataSection snapshots={snapshots} showGraph={showGraph} />,
    },
  ];

  let accordionOpenIdx: number | undefined = undefined;
  if (events.length > 0) {
    if (
      selectedEventId !== undefined &&
      events.find((event) => event._id === selectedEventId) !== undefined
    ) {
      accordionOpenIdx = sections.length;
    }

    sections.push({
      header: mkHeader('Events', IconWarning),
      body: <EventsSection events={events} />,
    });
  }

  return (
    <Slideout
      open={open}
      onClose={closeSlideout}
      title="Shipment Information"
      footer={
        <SlideoutFooter segment={segment} closeSlideout={closeSlideout} />
      }
    >
      <div
        style={{
          display: 'flex',
          gap: '24px',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Text size="sm">Loading...</Text>
        ) : error ? (
          <Text size="sm">
            An error occurred loading part of this page, please close and open
            again
          </Text>
        ) : (
          <Accordion sections={sections} initialOpenIdx={accordionOpenIdx} />
        )}
      </div>
    </Slideout>
  );
};

export default EventSlideout;
