import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconLightning: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 8 15">
    <Path
      d="M5 0.0710449V6.07106H8L3 14.0711V8.07106H0L5 0.0710449Z"
      fill="currentColor"
    />
  </IconBase>
);
