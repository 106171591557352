import React, { PropsWithChildren, ReactNode, forwardRef } from 'react';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import { Label } from '../../../../aurora/typography/Label/Label';
import styles from './InfoCard.module.scss';

interface Props {
  title: string | ReactNode;
  headerAction?: ReactNode;
  accordion?: boolean;
  showOpen?: boolean;
  onAccordionToggle?: (idx: number, open: boolean) => void;
}
const InfoCard = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    { title, headerAction, accordion, showOpen, children, onAccordionToggle },
    ref
  ) => {
    if (accordion) {
      return (
        <div ref={ref}>
          <Accordion
            sections={[{ header: title, body: children }]}
            className={styles.accordion}
            initialOpenIdx={showOpen ? 0 : undefined}
            onAccordionToggle={onAccordionToggle}
          />
        </div>
      );
    }

    const titleNode =
      typeof title === 'string' ? (
        <Label size="sm" color="strong900">
          {title}
        </Label>
      ) : (
        title
      );

    return (
      <div ref={ref} className={styles.box}>
        <div className={styles.header}>
          {titleNode}
          {headerAction}
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    );
  }
);

InfoCard.displayName = 'InfoCard';

export default InfoCard;
