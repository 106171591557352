import React from 'react';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import {
  ShipmentEntity,
  ShippoTrackingStatusEnum,
} from '../../../../../state/shipments/types';
import { AddressEntity } from '../../../../../state/addresses/types';
import { format, differenceInMilliseconds } from 'date-fns';
import InfoCard from '../InfoCard';
import styles from '../JourneyPageContainer.module.scss';
import { formatDateWithTime } from '../../../../../utils/dateUtil';

const lastAddressLine = (address: AddressEntity): string => {
  return `${address.city}, ${address.state} ${address.postalCode}`;
};

interface Props {
  shipment: ShipmentEntity;
}
const ShipmentDetailsCard = ({ shipment }: Props) => {
  const { origin, destination, shippoInfo } = shipment;
  if (!origin && !destination) {
    return null;
  }

  const startTimeString = shippoInfo?.tracking_history?.[0]?.status_date;
  const startTime = startTimeString ? new Date(startTimeString) : undefined;

  const deliveredEntry = shippoInfo?.tracking_history?.find(
    (entry) => entry.status === ShippoTrackingStatusEnum.DELIVERED
  );
  const endTimeString = deliveredEntry?.status_date;
  const endTime = endTimeString ? new Date(endTimeString) : undefined;
  const transitTime =
    startTime && endTime
      ? parseFloat(
          (
            differenceInMilliseconds(new Date(endTime), new Date(startTime)) /
            (1000 * 60 * 60)
          ).toFixed(1)
        )
      : undefined;

  // TODO(carrier-tracking): add start time, end time, transit time
  return (
    <InfoCard title="Shipment Details">
      <div className={styles.textSection}>
        <div>
          <Label span size="sm" color="strong900">
            {'Start Time: '}
          </Label>
          <Text span size="sm" color="sub600">
            {startTime ? formatDateWithTime(startTime) : '-'}
          </Text>
        </div>
        <div>
          <Label span size="sm" color="strong900">
            {'End Time: '}
          </Label>
          <Text span size="sm" color="sub600">
            {endTime ? format(endTime, 'MM/dd/yy hh:mm a') : '-'}
          </Text>
        </div>
        <div>
          <Label span size="sm" color="strong900">
            {'Transit Time: '}
          </Label>
          <Text span size="sm" color="sub600">
            {transitTime ? `${transitTime} hours` : '-'}
          </Text>
        </div>
      </div>

      {origin && <div className={styles.divider} />}
      {origin && (
        <>
          <Label size="sm" color="strong900">
            From
          </Label>
          <Text size="sm" color="sub600">
            {origin.addressLine1}
          </Text>
          {origin.addressLine2 && (
            <Text size="sm" color="sub600">
              {origin.addressLine2}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {lastAddressLine(origin)}
          </Text>
        </>
      )}

      {destination && <div className={styles.divider} />}

      {destination && (
        <>
          <Label size="sm" color="strong900">
            To
          </Label>
          {destination.addressName && (
            <Text size="sm" color="sub600">
              {destination.addressName}
            </Text>
          )}
          {destination.attn && (
            <Text size="sm" color="sub600">
              Attn: {destination.attn}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {destination.addressLine1}
          </Text>
          {destination.addressLine2 && (
            <Text size="sm" color="sub600">
              {destination.addressLine2}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {lastAddressLine(destination)}
          </Text>
        </>
      )}
    </InfoCard>
  );
};

export default ShipmentDetailsCard;
