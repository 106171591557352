import React from 'react';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import { JourneyEntity } from '../../../../../state/journeys/types';
import InfoCard from '../InfoCard';
import styles from '../JourneyPageContainer.module.scss';

// TODO(shipper-view): link to shipper view
interface Props {
  journey: JourneyEntity;
  showOpen: boolean;
}
const ShipperInfoCard = ({ journey, showOpen }: Props) => {
  const { deviceType, serialNumber, firmwareVersion } = journey;
  if (!deviceType || !serialNumber) {
    return (
      <InfoCard title="Shipper Information" accordion showOpen={showOpen}>
        <Label size="sm" color="strong900">
          Awaiting Shipper Assignment
        </Label>
        <Text size="sm" color="sub600">
          Shipper information will appear here once it has been added to the
          journey
        </Text>
      </InfoCard>
    );
  }

  return (
    <InfoCard title="Shipper Information" accordion showOpen={showOpen}>
      <div className={styles.textSection}>
        <div>
          <Label span size="sm" color="strong900">
            Type:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {deviceType}
          </Text>
        </div>

        <div>
          <Label span size="sm" color="strong900">
            Serial Number:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {serialNumber}
          </Text>
        </div>

        {firmwareVersion && (
          <div>
            <Label span size="sm" color="strong900">
              Firmware Version:&nbsp;
            </Label>
            <Text span size="sm" color="sub600">
              {firmwareVersion}
            </Text>
          </div>
        )}
      </div>
    </InfoCard>
  );
};

export default ShipperInfoCard;
