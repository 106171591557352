import React from 'react';
import { EventEntity, EventStatusesEnum } from '../../../state/events/types';
import {
  IconCircleCheck,
  IconDownload,
  IconGraph,
} from '../../../aurora/icons';
import { useDispatch, useSelector } from 'react-redux';
import { downloadCsvAction, markReviewedAction } from '../../../state/events';
import { AppDispatch } from '../../../state/store';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../state/auth';
import ActionButton from '../../../components/table/ActionButton';
import styles from './EventTableAction.module.scss';

interface Props {
  event: EventEntity;
  showGraph: () => void;
}
const EventTableAction = ({ event, showGraph }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const reviewAction = (
    <ActionButton
      label="Mark reviewed"
      icon={IconCircleCheck}
      onClick={() =>
        dispatch(
          markReviewedAction({
            axios: axiosPrivate,
            auth,
            eventIds: [event._id],
          })
        )
      }
    />
  );

  return (
    <div className={styles.action}>
      {event.status === EventStatusesEnum.NEW ? reviewAction : null}
      <ActionButton
        label="Download CSV"
        icon={IconDownload}
        onClick={() =>
          dispatch(
            downloadCsvAction({
              axios: axiosPrivate,
              auth,
              eventIds: [event._id],
            })
          )
        }
      />
      <ActionButton label="View graph" icon={IconGraph} onClick={showGraph} />
    </div>
  );
};

export default EventTableAction;
