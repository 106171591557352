import React, { useEffect, useState } from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import DeviceTypeCheckboxList from './DeviceTypeCheckboxList';
import { useSx } from 'dripsy';
import { DeviceType } from '../../../state/devices/types';

interface Props {
  onSubmit: (deviceTypes: DeviceType[]) => void;
  onClear: () => void;
  onCancel: () => void;
  deviceTypes: DeviceType[] | null;
  filteredDeviceTypes: DeviceType[];
}

const DeviceTypePopover = ({
  onSubmit,
  onClear,
  onCancel,
  deviceTypes,
  filteredDeviceTypes,
}: Props) => {
  const sx = useSx();
  const [selectedDeviceTypes, setSelectedDeviceTypes] =
    useState<DeviceType[]>(filteredDeviceTypes);

  useEffect(() => {
    setSelectedDeviceTypes(filteredDeviceTypes);
  }, [filteredDeviceTypes]);

  const onApply = () => {
    if (selectedDeviceTypes.length === 0) {
      onClear();
    } else {
      onSubmit(selectedDeviceTypes);
    }
  };

  return (
    <div style={{ minWidth: '300px' }}>
      <div
        style={sx({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Text size="sm" sx={{ fontWeight: '500' }}>
          Shippers
        </Text>
        <Button variant="ghostPrimary" label="Clear all" onClick={onClear} />
      </div>
      <DeviceTypeCheckboxList
        selectedDeviceTypes={selectedDeviceTypes}
        deviceTypes={deviceTypes}
        onChange={(deviceTypes) => setSelectedDeviceTypes(deviceTypes)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
          gap: '12px',
        }}
      >
        <Button variant="secondary" label="Cancel" onClick={onCancel} />
        <Button variant="primary" label="Apply" onClick={onApply} />
      </div>
    </div>
  );
};

export default DeviceTypePopover;
