import React, { useState, ReactNode, FunctionComponent } from 'react';
import DataModal from '../DataModal';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityState } from '../../state/store';
import { EntityStateExtension, LoadDataAction } from '../../state/types';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { selectAuth } from '../../state/auth';
import { IconDotsVertical } from '../../aurora/icons';
import { IconProps } from '../../aurora/icons/IconBase';
import ActionButton from './ActionButton';
import { Button } from '../../aurora/components/Button/legacy/Button';

interface Props<T> {
  icon?: FunctionComponent<IconProps>;
  modalTitle?: string;
  loadDataAction: LoadDataAction<T>;
  stateName: EntityStateExtension<T>;
  modalWidth?: string;
  modalContent: (
    onDataChange: () => Promise<void>,
    onClose: () => void
  ) => ReactNode;
  label: string;
  isLabeledButton?: boolean;
  isDisabled?: boolean;
}

const ModalAction = <T,>({
  icon,
  modalTitle,
  loadDataAction,
  stateName,
  modalContent,
  modalWidth,
  label,
  isLabeledButton = false,
  isDisabled = false,
}: Props<T>) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const state = useEntityState(stateName);

  const onDataChange = async () => {
    const action = await loadDataAction(axiosPrivate, auth!, state);
    dispatch(action);
  };
  const onClose = () => setShowModal(false);

  return (
    <>
      {isLabeledButton ? (
        <Button
          variant="secondary"
          label={label}
          iconLeft={icon}
          onClick={() => setShowModal(true)}
          sx={{ marginX: '12px' }}
          disabled={isDisabled ?? false}
        />
      ) : (
        <ActionButton
          label={label}
          icon={icon || IconDotsVertical}
          onClick={() => setShowModal(!showModal)}
        />
      )}
      <DataModal
        title={modalTitle}
        show={showModal}
        onClose={onClose}
        width={modalWidth}
      >
        {modalContent(onDataChange, onClose)}
      </DataModal>
    </>
  );
};

export default ModalAction;
