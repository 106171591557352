import mapboxgl from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { FeatureCollection, Feature, Point, GeoJsonProperties } from 'geojson';

/* 
GeoJSON is an open standard file format for representing map data. 
Mapbox web services and APIs serve geospatial data as GeoJSON.

A GeoJSON object will have a `type` property and then other properties depending on the value of type.  Here's an exmaple of how we are using geoJSON:
{
       "type": "FeatureCollection",
       "features": [{
           "type": "Feature",
           "geometry": {
               "type": "Point",
               "coordinates": [102.0, 0.5]
           },
           "properties": {
               timestampArray: string[]; // array of the timestamps for all snapshots that happened at that point
               secSinceStartArray: number[]; // array of all secSinceStart values for snapshots that happened at that point
               indexArray: number[]; // array of the indicies of the snapshots that happened at that point
               pointIndex: number; // index of the Point in the sgement
           }
       }, {
           "type": "Feature",
           "geometry": {
               "type": "LineString",
               "coordinates": [
                   [102.0, 0.0],
                   [103.0, 1.0],
                   [104.0, 0.0],
                   [105.0, 1.0]
               ]
           },
           "properties": {
             // same as above
            }
          }]
}
*/

export const convertPointGroupToGeoJSON = (
  pointGroup: MapPointGroup
): FeatureCollection<Point, GeoJsonProperties> => {
  const features: Feature<Point, GeoJsonProperties>[] = pointGroup.points.map(
    (point) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.longitude, point.latitude],
      },
      properties: point.properties,
    })
  );

  return {
    type: 'FeatureCollection',
    features: features,
  };
};

export const addGeoJSONSource = (
  map: mapboxgl.Map,
  sourceId: string,
  geojson: FeatureCollection<Point, GeoJsonProperties>
) => {
  map.addSource(sourceId, {
    type: 'geojson',
    data: geojson,
  });
};
