import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconSun: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase {...props} viewBox="0 0 12 12">
    <Path
      d="M6 9.27273C4.19252 9.27273 2.72727 7.80747 2.72727 6C2.72727 4.19252 4.19252 2.72727 6 2.72727C7.80747 2.72727 9.27273 4.19252 9.27273 6C9.27273 7.80747 7.80747 9.27273 6 9.27273ZM6 8.18182C7.20496 8.18182 8.18182 7.20496 8.18182 6C8.18182 4.79501 7.20496 3.81818 6 3.81818C4.79501 3.81818 3.81818 4.79501 3.81818 6C3.81818 7.20496 4.79501 8.18182 6 8.18182ZM5.45455 0H6.54545V1.63636H5.45455V0ZM5.45455 10.3636H6.54545V12H5.45455V10.3636ZM1.37167 2.14305L2.14305 1.37167L3.30014 2.52875L2.52875 3.30014L1.37167 2.14305ZM8.69984 9.47127L9.47127 8.69984L10.6283 9.85696L9.85696 10.6283L8.69984 9.47127ZM9.85696 1.37167L10.6283 2.14305L9.47127 3.30014L8.69984 2.52875L9.85696 1.37167ZM2.52875 8.69984L3.30014 9.47127L2.14305 10.6283L1.37167 9.85696L2.52875 8.69984ZM12 5.45455V6.54545H10.3636V5.45455H12ZM1.63636 5.45455V6.54545H0V5.45455H1.63636Z"
      fill="currentColor"
    />
  </IconBase>
);
