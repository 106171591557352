import { GeoJsonProperties } from 'geojson';
import mapboxgl from 'mapbox-gl';

export const handleMouseEnter = (
  map: mapboxgl.Map,
  e: mapboxgl.MapMouseEvent,
  popup: mapboxgl.Popup,
  onHoverHtml: (properties: GeoJsonProperties) => string
) => {
  if (e.features && e.features.length > 0) {
    map.getCanvas().style.cursor = 'pointer';
    const { coordinates } = e.features[0].geometry;

    const popupContent = onHoverHtml(e.features[0].properties);

    popup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
  }
};

export const handleMouseLeave = (map: mapboxgl.Map, popup: mapboxgl.Popup) => {
  map.getCanvas().style.cursor = '';
  popup.remove();
};
