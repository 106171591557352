import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SegmentsApi from '../../../../api/segmentsApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { JourneyEntity } from '../../../../state/journeys/types';
import { setSegment } from '../../../../state/segments';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import JourneyInfoSlideoutContent from './JourneyInfoSlideoutContent';
import styles from './JourneyInfoSlideout.module.scss';
import { setShipmentsForJourney } from '../../../../state/journeys';
import JourneysApi from '../../../../api/journeysApi';

interface Props {
  journey: JourneyEntity | undefined;
  open: boolean;
  closeSlideout: () => void;
  isArtycViewer: boolean;
}
const JourneyInfoSlideout = ({
  journey,
  open,
  closeSlideout,
  isArtycViewer,
}: Props) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  // TODO: condense requests into one?
  const fetchData = async () => {
    if (journey === undefined) {
      return;
    }

    const shipments = await JourneysApi.getShipmentsForJourney(
      axios,
      auth,
      journey._id
    );
    dispatch(setShipmentsForJourney({ journey: journey._id, shipments }));

    if (journey.segmentId !== undefined && journey.segmentId !== null) {
      const loadedSegment = await SegmentsApi.getSegment(
        axios,
        auth,
        journey.segmentId
      );
      if (loadedSegment) {
        dispatch(setSegment(loadedSegment));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [journey]);

  if (journey === undefined) {
    return null;
  }

  const footerButton = (
    <Button
      className={styles.button}
      variant="primary"
      label="View Shipment Details"
      href={`/portal/journey/${journey._id}`}
    />
  );

  return (
    <Slideout
      open={open}
      onClose={closeSlideout}
      title="Journey Details"
      footer={footerButton}
    >
      <JourneyInfoSlideoutContent
        isArtycViewer={isArtycViewer}
        journey={journey}
      />
    </Slideout>
  );
};

export default JourneyInfoSlideout;
