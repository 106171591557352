import React from 'react';
import { SegmentEntity } from '../../../../../state/segments/types';
import SnapshotsMap from '../map/SnapshotsMap';
import { useSelector } from 'react-redux';
import { selectSnapshotsBySegment } from '../../../../../state/segments';
import InfoCard from '../InfoCard';

interface Props {
  segments: SegmentEntity[];
  isArtycViewer: boolean;
}
const SnapshotsMapContainer = ({ segments, isArtycViewer }: Props) => {
  const hasGeoData = segments.some((segment) => segment.hasGeoData);
  if (!hasGeoData || !isArtycViewer) {
    return null;
  }

  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);

  return (
    <InfoCard title="Location Tracking">
      <SnapshotsMap snapshotsBySegment={snapshotsBySegment} />
    </InfoCard>
  );
};

export default SnapshotsMapContainer;
