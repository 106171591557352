import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconTemperature: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 8 13">
    <Path
      d="M1.71429 2.28571C1.71429 1.02335 2.73763 0 4 0C5.26234 0 6.28571 1.02335 6.28571 2.28571V5.2884C7.322 6.0112 8 7.21217 8 8.57143C8 10.7806 6.20914 12.5714 4 12.5714C1.79086 12.5714 0 10.7806 0 8.57143C0 7.21217 0.678011 6.0112 1.71429 5.2884V2.28571ZM2.36811 6.22577C1.60539 6.75777 1.14286 7.62469 1.14286 8.57143C1.14286 10.1494 2.42205 11.4286 4 11.4286C5.57794 11.4286 6.85714 10.1494 6.85714 8.57143C6.85714 7.62469 6.39463 6.75777 5.63189 6.22577L5.14286 5.88463V2.28571C5.14286 1.65453 4.6312 1.14286 4 1.14286C3.3688 1.14286 2.85714 1.65453 2.85714 2.28571V5.88463L2.36811 6.22577ZM1.71429 8.57143H6.28571C6.28571 9.83377 5.26234 10.8571 4 10.8571C2.73763 10.8571 1.71429 9.83377 1.71429 8.57143Z"
      fill="currentColor"
    />
  </IconBase>
);
