import React from 'react';
import { SegmentSnapshotEntity } from '../../../../../state/segments/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { htmlLegendPlugin } from './Legend';
import { convertTemp, mkChartOptions, mkDatapoint } from './chartOptions';
import 'chartjs-adapter-date-fns';
import { genColorHex } from './colorUtils';
import { TimeRange } from './SnapshotsGraphContainer';

export interface ExternalTempDatapoint {
  tempC: number;
  timestamp: number;
}

export interface ExternalTempData {
  label: string;
  offsetMinutes: number;
  datapoints: ExternalTempDatapoint[];
}

interface Props {
  snapshots: SegmentSnapshotEntity[];
  externalTempData: ExternalTempData[];
  tempUnit: 'C' | 'F';
  isArtycViewer: boolean;
  tempThresholdsC?: number[];
  minTempAxisC?: number;
  maxTempAxisC?: number;
  showLidOpen: boolean;
  showDateAsDuration: boolean;
  selectedTimeRange?: TimeRange;
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin
);

const SnapshotsGraph = React.forwardRef<ChartJS<'line'> | undefined, Props>(
  (
    {
      snapshots,
      externalTempData,
      tempUnit,
      isArtycViewer,
      tempThresholdsC,
      minTempAxisC,
      maxTempAxisC,
      showLidOpen,
      showDateAsDuration,
      selectedTimeRange,
    },
    ref
  ) => {
    const chartContainerRef = React.useRef<HTMLDivElement>(null);
    const startDate = new Date(snapshots[0]?.timestamp);
    const endDate = new Date(snapshots[snapshots.length - 1]?.timestamp);

    const lidOpens = [];
    if (showLidOpen) {
      let lidOpenStart: number | undefined = undefined;
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        if (snapshot.lidOpen && lidOpenStart === undefined) {
          lidOpenStart = new Date(snapshot.timestamp).valueOf();
        }

        if (
          // last snapshot or lid closed
          (!snapshot.lidOpen || i === snapshots.length - 1) &&
          lidOpenStart !== undefined
        ) {
          lidOpens.push({
            start: lidOpenStart,
            end: new Date(snapshot.timestamp).valueOf(),
          });
          lidOpenStart = undefined;
        }
      }
    }

    const options = mkChartOptions(
      showDateAsDuration ? 'timeDuration' : 'time',
      tempUnit,
      tempThresholdsC,
      lidOpens,
      minTempAxisC,
      maxTempAxisC,
      startDate,
      selectedTimeRange
    );

    const data: ChartData<'line'> = {
      datasets: [
        {
          label: `${isArtycViewer ? 'Heat Sink' : 'Ambient'} (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.hs))
          ),
          borderColor: '#eea23e',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#eea23e',
        },
        {
          label: `${isArtycViewer ? 'Sleeve' : 'Internal'} (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.sleeve))
          ),
          borderColor: '#0c77ff',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#0c77ff',
        },
        {
          label: 'Battery (%)',
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, snapshot.soc)
          ),
          borderColor: '#5a6473',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#5a6473',
        },
        ...(isArtycViewer
          ? [
              {
                label: `Evap (°${tempUnit})`,
                data: snapshots.map((snapshot) =>
                  mkDatapoint(
                    snapshot,
                    convertTemp(tempUnit)(snapshot.temps.evap)
                  )
                ),
                borderColor: '#7c31c6',
                yAxisID: 'temperature',
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: '#7c31c6',
              },
              {
                label: 'Output (V)',
                data: snapshots.map((snapshot) =>
                  mkDatapoint(snapshot, parseFloat(snapshot.outputV))
                ),
                borderColor: '#29a63b',
                yAxisID: 'voltage',
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: '#29a63b',
              },
            ]
          : []),
        ...externalTempData
          .filter((data) => data.datapoints.length > 0 && snapshots.length > 0)
          .map((data, i) => {
            const startTime = startDate.valueOf();
            const endTime = endDate.valueOf();
            const offset = startTime - data.datapoints[0].timestamp;
            const color = genColorHex(i);
            return {
              label: data.label,
              data: data.datapoints
                .filter((point) => {
                  const timestamp =
                    point.timestamp + offset + data.offsetMinutes * 60 * 1000;
                  return timestamp <= endTime && timestamp >= startTime;
                })
                .map((point) => ({
                  x: point.timestamp + offset + data.offsetMinutes * 60 * 1000,
                  y: convertTemp(tempUnit)(point.tempC),
                })),
              borderColor: color,
              yAxisID: 'temperature',
              pointRadius: 0,
              pointHoverRadius: 4,
              pointHoverBackgroundColor: color,
            };
          }),
      ],
    };

    return (
      <>
        <div
          id="legend-container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '12px',
          }}
        ></div>
        <div
          ref={chartContainerRef}
          style={{ position: 'relative', zIndex: '2' }}
        >
          <Line
            ref={ref}
            options={options}
            data={data}
            plugins={[htmlLegendPlugin]}
          />
        </div>
      </>
    );
  }
);

SnapshotsGraph.displayName = 'SnapshotsGraph';

export default SnapshotsGraph;
