import { AddressEntity } from '../addresses/types';
import { OptionalField } from '../types';

export enum ShipmentTypeEnum {
  REGULATED = 'REGULATED',
  UNREGULATED = 'UNREGULATED',
}

export enum ShippoCarrierEnum {
  FEDEX = 'fedex',
  UPS = 'ups',
}

export enum ShippoTrackingStatusEnum {
  PRE_TRANSIT = 'PRE_TRANSIT',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  RETURNED = 'RETURNED',
  UNKNOWN = 'UNKNOWN',
}

export interface ShippoLocation {
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface ShippoServiceLevel {
  token: string;
  name: string;
}

export interface ShippoTrackingStatusDetail {
  object_created: string; // ISO string date
  object_updated: string; // ISO string date
  object_id: string;
  status: ShippoTrackingStatusEnum;
  status_details: string;
  status_date: string; // ISO string date
  location: ShippoLocation;
}

export interface ShippoTrackingHistory {
  object_created: string; // ISO string date
  object_id: string;
  status: ShippoTrackingStatusEnum;
  status_details: string;
  status_date: string; // ISO string date
  location: ShippoLocation;
}

export interface ShippoInfo {
  carrier: ShippoCarrierEnum;
  tracking_number: string;
  address_from: ShippoLocation;
  address_to: ShippoLocation;
  transaction: string;
  original_eta: string; // ISO string date
  eta: string; // ISO string date
  servicelevel: ShippoServiceLevel;
  metadata: string;
  tracking_status: ShippoTrackingStatusDetail;
  tracking_history: ShippoTrackingHistory[];
}

export interface ShipmentEntity {
  _id: string;
  pid: string;
  journey: string;
  leg: number;
  shipmentType: ShipmentTypeEnum;
  segment: OptionalField<string>;
  trackingNumber: OptionalField<string>;
  carrier: OptionalField<ShippoCarrierEnum>;
  shippoInfo: OptionalField<ShippoInfo>;
  origin: OptionalField<AddressEntity>;
  destination: OptionalField<AddressEntity>;
}

export interface ShipmentCarrierUpdate {
  [shipmentId: string]: {
    trackingNumber: string;
    carrierName: string;
  };
}

export const shippoCarrierDisplayNames: {
  [key in ShippoCarrierEnum]: string;
} = {
  [ShippoCarrierEnum.FEDEX]: 'FedEx',
  [ShippoCarrierEnum.UPS]: 'UPS',
};

export const shippoTrackingStatusDisplayNames: {
  [key in ShippoTrackingStatusEnum]: string;
} = {
  [ShippoTrackingStatusEnum.PRE_TRANSIT]: 'Pre-Transit',
  [ShippoTrackingStatusEnum.TRANSIT]: 'In Transit',
  [ShippoTrackingStatusEnum.DELIVERED]: 'Delivered',
  [ShippoTrackingStatusEnum.RETURNED]: 'Returned',
  [ShippoTrackingStatusEnum.UNKNOWN]: 'Unknown',
};
