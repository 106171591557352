import { FeatureCollection, Feature, Point } from 'geojson';

export const mergeBounds = (
  accBounds: [[number, number], [number, number]],
  bounds: [[number, number], [number, number]]
): [[number, number], [number, number]] => {
  return [
    [
      Math.min(accBounds[0][0], bounds[0][0]),
      Math.min(accBounds[0][1], bounds[0][1]),
    ],
    [
      Math.max(accBounds[1][0], bounds[1][0]),
      Math.max(accBounds[1][1], bounds[1][1]),
    ],
  ];
};

export const calculateBoundingBox = (
  geojsonPoints: FeatureCollection<Point>
): [[number, number], [number, number]] => {
  return geojsonPoints.features.reduce(
    (
      acc: [[number, number], [number, number]],
      feature: Feature<Point>
    ): [[number, number], [number, number]] => {
      const [long, lat] = feature.geometry.coordinates;
      return [
        [Math.min(acc[0][0], long), Math.min(acc[0][1], lat)],
        [Math.max(acc[1][0], long), Math.max(acc[1][1], lat)],
      ];
    },
    [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ] as [[number, number], [number, number]]
  );
};

export const isValidLatLong = (lat: number, long: number) => {
  return (
    lat >= -90 &&
    lat <= 90 &&
    long >= -180 &&
    long <= 180 &&
    // temporary condition until we change shipper behavior
    !(lat === 0 && long === 0)
  );
};
