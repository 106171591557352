import mapboxgl from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { calculateBoundingBox, mergeBounds } from './mapBounds';
import { addGeoJSONSource, convertPointGroupToGeoJSON } from './mapData';
import { handleMouseEnter, handleMouseLeave } from './mapHandlers';

const renderPointGroup = (
  map: mapboxgl.Map,
  pointGroup: MapPointGroup,
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  const geojsonFeatures = convertPointGroupToGeoJSON(pointGroup);
  if (geojsonFeatures.features.length === 0) {
    return [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ];
  }

  // add points as a data source to the map
  addGeoJSONSource(map, pointGroup.groupId, geojsonFeatures);
  // render the points
  map.addLayer(pointGroup.groupStyle);

  if (pointGroup.onHoverHtml !== undefined) {
    const { onHoverHtml } = pointGroup;
    map.on('mouseenter', pointGroup.groupId, (e) =>
      handleMouseEnter(map, e, popup, onHoverHtml)
    );

    map.on('mouseleave', pointGroup.groupId, () =>
      handleMouseLeave(map, popup)
    );
  }

  return calculateBoundingBox(geojsonFeatures);
};

export const renderPointGroups = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  let accBounds: [[number, number], [number, number]] = [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ];

  for (const pointGroup of pointGroups) {
    const bounds = renderPointGroup(map, pointGroup, popup);
    accBounds = mergeBounds(accBounds, bounds);
  }

  return accBounds;
};
