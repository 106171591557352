import React, { ComponentType } from 'react';
import styles from './StatusBadge.module.scss';
import { IconDot } from '../../icons';
import { IconProps } from '../../icons/IconBase';

type StatusBadgeStatus = 'green' | 'gray' | 'blue' | 'orange' | 'purple';
type StatusBadgeStyle = 'light';
interface Props {
  content: string;
  status: StatusBadgeStatus;
  icon?: ComponentType<IconProps>;
  style?: StatusBadgeStyle;
  onClick?: () => void;
}
const StatusBadge = ({ content, status, icon: Icon, onClick }: Props) => {
  const colorStyle = styles[status];

  const icon = Icon ? (
    <Icon width={16} height={16} />
  ) : (
    <IconDot width={16} height={16} />
  );

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.content}>
        <div className={colorStyle}>{icon}</div>
        <span>{content}</span>
      </div>
    </div>
  );
};

export default StatusBadge;
