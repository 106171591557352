import React, { useState, useEffect } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './calendar.scss';
import { Button } from '../Button/legacy/Button';
import { useSx } from 'dripsy';

export type DateType = Date | undefined;
export type DateRangeType = [DateType, DateType];

interface Props {
  initialRange: DateRangeType;
  onSetRange: (range: DateRangeType) => void;
  onClose?: () => void;
  excludeButtons?: boolean;
  maxDate?: Date;
}

const DateRangeCalendar = ({
  onSetRange,
  onClose,
  initialRange,
  maxDate,
  excludeButtons = false,
}: Props) => {
  const [dateRange, setDateRange] = useState<Range>({
    startDate: initialRange[0],
    endDate: initialRange[1],
    key: 'selection',
  });

  useEffect(() => {
    if (excludeButtons) {
      onSetRange([dateRange.startDate, dateRange.endDate]);
    }
  }, [dateRange, onSetRange]);

  const onContinue = () => onSetRange([dateRange.startDate, dateRange.endDate]);

  const sx = useSx();
  const selectedDatesColor = sx({
    color: 'gray600v1',
    backgroundColor: 'primary25',
  });

  return (
    <>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => setDateRange(item.selection)}
        moveRangeOnFirstSelection={false}
        ranges={[dateRange]}
        color={selectedDatesColor.color}
        rangeColors={[selectedDatesColor.backgroundColor]}
        maxDate={maxDate}
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
      />
      {!excludeButtons && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {onClose ? (
            <Button variant="secondary" label="Close" onClick={onClose} />
          ) : null}
          <Button variant="primary" label="Continue" onClick={onContinue} />
        </div>
      )}
    </>
  );
};

export default DateRangeCalendar;
