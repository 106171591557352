import { AxiosInstance } from 'axios';
import { AddressEntity, AddressTypeEnum } from '../state/addresses/types';
import { Auth } from '../state/auth/types';
import { DataPayload } from '../state/types';
import { genericFetch } from './api';

const ARTYC_ADDRESSES_URL = '/artyc/addresses';
const COMPANY_ADDRESSES_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/addresses`;

const AddressesApi = {
  getLabAddresses: async (
    axios: AxiosInstance,
    auth: Auth | null,
    companyId: string
  ): Promise<DataPayload<AddressEntity>> => {
    const response = await genericFetch<DataPayload<AddressEntity>>(
      axios,
      auth,
      ARTYC_ADDRESSES_URL,
      COMPANY_ADDRESSES_URL(auth),
      {
        paginate: false,
        filters: {
          addressTypes: [AddressTypeEnum.LAB],
          companyIds: [companyId],
        },
      }
    );

    return response;
  },
};

export default AddressesApi;
