import React, { ComponentType } from 'react';
import { IconProps } from '../../icons/IconBase';
import { BaseButton, ButtonActionProps } from './BaseButton';
import styles from './LinkButton.module.scss';

export const LinkButtonStyle = {
  Gray: 'gray',
  Black: 'black',
  Primary: 'primary',
  Error: 'error',
  White: 'white',
} as const;
type LinkButtonStyleType =
  (typeof LinkButtonStyle)[keyof typeof LinkButtonStyle];

export const LinkButtonSize = {
  Medium: 'md',
  Small: 'sm',
} as const;
type LinkButtonSizeType = (typeof LinkButtonSize)[keyof typeof LinkButtonSize];

export type LinkButtonProps = {
  style: LinkButtonStyleType;
  size: LinkButtonSizeType;
  label: string;
  underline?: boolean;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
} & ButtonActionProps;
export const LinkButton = ({
  style,
  size,
  underline = false,
  ...rest
}: LinkButtonProps) => {
  return (
    <BaseButton
      iconSize={size === LinkButtonSize.Small ? 16 : 20}
      labelSize={size === LinkButtonSize.Small ? 'xs' : 'sm'}
      disabledClassName={`${styles.disabled}`}
      baseClassName={styles.button}
      variantClassName={`${underline && styles.underline} ${styles[style]}`}
      {...rest}
    />
  );
};
