import React from 'react';
import Banner from '../../../../../aurora/components/Banner/Banner';
import { EventEntity } from '../../../../../state/events/types';

interface Props {
  events: EventEntity[];
  onViewEvents: () => void;
}
const ExcursionBanner = ({ events, onViewEvents }: Props) => {
  if (events.length === 0) {
    return null;
  }

  return (
    <Banner
      label="Temperature Excursion"
      description="This shipment requires your attention"
      onClick={onViewEvents}
    />
  );
};

export default ExcursionBanner;
