import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DownloadCsvButton from '../DownloadCsvButton';
import styles from './JourneyPageContainer.module.scss';
import { ViewAsCustomerToggle } from '../ViewAsCustomerToggle';
import { JourneyEntity } from '../../../../state/journeys/types';
import { IconChevronRight } from '../../../../aurora/icons';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../state/store';
import { Label } from '../../../../aurora/typography/Label/Label';
import {
  ShipmentEntity,
  ShipmentTypeEnum,
} from '../../../../state/shipments/types';
import RegulatedShipmentContainer from './RegulatedShipmentContainer';
import UnregulatedShipmentContainer from './UnregulatedShipmentContainer';
import LegSelector from './LegSelector';
import JourneyStatusBadge from '../journeyTable/JourneyStatusBadge';
import { getSelectedLegIndex } from '../../../../utils/journeyUtils';

interface Props {
  isArtycViewer: boolean;
  journey: JourneyEntity;
  shipments: ShipmentEntity[];
}
const JourneyPageContainer = ({ isArtycViewer, journey, shipments }: Props) => {
  const segment = useSelector((root: RootState) =>
    journey.segmentId !== undefined && journey.segmentId !== null
      ? root.segments.segmentById[journey.segmentId]
      : undefined
  );

  const [selectedShipment, setSelectedShipment] = useState<ShipmentEntity>(
    shipments[0]
  );

  // Set the starting selected shipment when the componbased on the journey status
  useEffect(() => {
    const legIndex = getSelectedLegIndex(journey, shipments);
    const newSelectedShipment = shipments[legIndex];

    if (
      newSelectedShipment !== undefined &&
      newSelectedShipment !== selectedShipment
    ) {
      setSelectedShipment(newSelectedShipment);
    }
  }, [journey, shipments]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <Label size="lg" className={styles.heading}>
            <Link to="/portal/journeys">Journey</Link>
            <IconChevronRight height={24} width={24} />
            {journey.pid}
          </Label>
          <JourneyStatusBadge status={journey.status} />
        </div>
        <div className={styles.buttons}>
          <ViewAsCustomerToggle />
          {isArtycViewer ? (
            <DownloadCsvButton
              journeys={[journey]}
              text="Download Full Log"
              fullLog={true}
              isArtycViewer={isArtycViewer}
            />
          ) : null}
          <DownloadCsvButton
            journeys={[journey]}
            text="Download Shipment"
            isArtycViewer={isArtycViewer}
          />
        </div>
      </div>
      <div className={styles.legSelector}>
        {[...(shipments || [])]
          .sort((a, b) => a.leg - b.leg)
          .map((shipment) => (
            <LegSelector
              key={shipment.leg}
              shipment={shipment}
              isSelected={shipment.leg === selectedShipment.leg}
              setSelectedShipment={setSelectedShipment}
            />
          ))}
      </div>
      <div>
        {selectedShipment.shipmentType === ShipmentTypeEnum.REGULATED ? (
          <RegulatedShipmentContainer
            journey={journey}
            segment={segment}
            shipment={selectedShipment}
            isArtycViewer={isArtycViewer}
          />
        ) : (
          <UnregulatedShipmentContainer
            journey={journey}
            shipment={selectedShipment}
            isArtycViewer={isArtycViewer}
          />
        )}
      </div>
    </div>
  );
};

export default JourneyPageContainer;
