import React, { useState } from 'react';
import { IconCircleWarningFill, IconClose } from '../../icons';
import { Label } from '../../typography/Label/Label';
import { Text } from '../../typography/Text/Text';
import { CompactButton } from '../Button/CompactButton';
import { LinkButton } from '../Button/LinkButton';
import styles from './Banner.module.scss';

interface Props {
  label: string;
  description: string;
  onClick: () => void;
}
const Banner = ({ label, description, onClick }: Props) => {
  const [isDismissed, setIsDismissed] = useState(false);

  if (isDismissed) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <div />
      <div className={styles.content}>
        <IconCircleWarningFill width={20} height={20} />
        <Label size="sm">{label}</Label>
        <span>∙</span>
        <Text size="sm">{description}</Text>
        <LinkButton
          onClick={onClick}
          label="View Details"
          size={'md'}
          style={'white'}
        />
      </div>
      <CompactButton
        onClick={() => setIsDismissed(true)}
        icon={IconClose}
        style={'ghost'}
        size="lg"
        className={styles.dismissButton}
      />
    </div>
  );
};

export default Banner;
