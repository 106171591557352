import React, { ComponentType, MouseEventHandler, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IconProps } from '../../icons/IconBase';
import { IconLoading } from '../../icons';
import styles from './BaseButton.module.scss';
import { Label } from '../../typography/Label/Label';
import { BaseTheme } from '../../theme/baseTheme';

interface BaseButtonProps {
  label?: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  baseClassName?: string;
  variantClassName?: string;
  disabledClassName?: string;
  iconSize?: number;
  labelSize?: keyof BaseTheme['text']['label'];
}

interface ClickButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  href?: never;
}
interface LinkButtonProps {
  href: string;
  onClick?: never;
  disabled?: never;
  loading?: never;
}
// must either have onClick or href but not both
// href is just to navigate to a new page. can be right clicked to open in new tab
// onClick is more general and can be disabled
export type ButtonActionProps = ClickButtonProps | LinkButtonProps;

type Props = ButtonActionProps & BaseButtonProps;

export const BaseButton = ({
  label,
  iconLeft,
  iconRight,
  disabled,
  loading,
  onClick,
  href,
  baseClassName,
  variantClassName,
  disabledClassName,
  iconSize = 20,
  labelSize = 'sm',
}: Props) => {
  const isDisabled = disabled || loading;
  const buttonRef = useRef(null);

  const renderIcon = (Icon: ComponentType<IconProps>) => {
    return <Icon width={iconSize} height={iconSize} />;
  };

  const buttonClasses = `${
    disabled ? `${disabledClassName} ${styles.disabled}` : variantClassName
  } ${loading ? styles.loading : ''} ${baseClassName} ${styles.button}`;

  const ButtonContent = () => (
    <>
      {iconLeft ? renderIcon(iconLeft) : null}
      {loading ? (
        <IconLoading width={iconSize} height={iconSize} />
      ) : label ? (
        <Label size={labelSize}>{label}</Label>
      ) : null}
      {iconRight ? renderIcon(iconRight) : null}
    </>
  );

  return href ? (
    <Link to={href} ref={buttonRef} className={buttonClasses}>
      <ButtonContent />
    </Link>
  ) : (
    <button
      ref={buttonRef}
      className={buttonClasses}
      onClick={onClick}
      disabled={isDisabled}
    >
      <ButtonContent />
    </button>
  );
};
