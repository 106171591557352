import React, { ComponentType } from 'react';
import { IconProps } from '../../aurora/icons/IconBase';
import { Tooltip } from '../../aurora/components/Tooltip/Tooltip';
import { CompactButton } from '../../aurora/components/Button/CompactButton';
import { ButtonActionProps } from '../../aurora/components/Button/BaseButton';

type BaseActionButtonProps = {
  icon: ComponentType<IconProps>;
  label: string;
  disabled?: boolean;
};

type Props = ButtonActionProps & BaseActionButtonProps;
const ActionButton = ({ onClick, icon, disabled, label, href }: Props) => {
  return (
    <Tooltip label={label} placement={'top'}>
      {href !== undefined ? (
        <CompactButton
          style={'ghost'}
          size={'lg'}
          icon={icon}
          href={href}
          disabled={disabled}
        />
      ) : (
        <CompactButton
          style={'ghost'}
          size={'lg'}
          icon={icon}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </Tooltip>
  );
};

export default ActionButton;
