import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import './SupportForm.scss';
import SupportApi from '../../../api/supportApi';
import { Button } from '../../../aurora/components/Button/legacy/Button';

const SupportForm = () => {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [feedbackClassName, setFeedbackClassName] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedbackClassName('');
    setFeedback('Submitting...');
    try {
      await SupportApi.createSupportRequest(axiosPrivate, message);
      setIsSubmitting(false);
      setFeedbackClassName('success');
      setFeedback('Request Submitted!');
      setMessage('');
    } catch (err: any) {
      setIsSubmitting(false);
      setFeedbackClassName('failure');
      if (err.response) {
        setFeedback(err.response?.data?.message || 'Submission Failed!');
      } else {
        setFeedback('No response from server.');
      }
    }
  };

  // Set a timer to clear the feedback message after 3 seconds
  useEffect(() => {
    let timer: any;
    if (feedback) {
      timer = setTimeout(() => {
        setFeedback(null);
        setFeedbackClassName('');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [feedback]);

  return (
    <div className="support">
      <div className="support-heading-container">
        <h2 className="support-heading">Get in touch</h2>
        <p className="support-subheading">We&apos;re happy to help!</p>
      </div>
      <div className="support-form-container">
        <p className="support-form-instructions">
          Please fill in the form and describe the issue:
        </p>
        <form className="support-form" onSubmit={handleSubmit}>
          <textarea
            className="support-form-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
          />
          <Button
            variant="primary"
            label="Submit"
            disabled={isSubmitting}
            onClick={(e) => e}
          />
          {feedback && <p className={feedbackClassName}>{feedback}</p>}
        </form>
      </div>
    </div>
  );
};

export default SupportForm;
