import React, { ComponentType } from 'react';
import { IconProps } from '../../icons/IconBase';
import { BaseButton, ButtonActionProps } from './BaseButton';
import styles from './CompactButton.module.scss';

export const CompactButtonStyle = {
  Stroke: 'stroke',
  Ghost: 'ghost',
  White: 'white',
} as const;
type CompactButtonStyleType =
  (typeof CompactButtonStyle)[keyof typeof CompactButtonStyle];

export const CompactButtonSize = {
  Large: 'lg',
  Medium: 'md',
} as const;
type CompactButtonSizeType =
  (typeof CompactButtonSize)[keyof typeof CompactButtonSize];

export type CompactButtonProps = {
  style: CompactButtonStyleType;
  size: CompactButtonSizeType;
  icon: ComponentType<IconProps>;
  rounded?: boolean;
  className?: string;
} & ButtonActionProps;
export const CompactButton = ({
  style,
  size,
  icon,
  rounded,
  className,
  ...rest
}: CompactButtonProps) => {
  return (
    <BaseButton
      disabledClassName={`${styles.disabled} ${styles[size]}`}
      baseClassName={`${styles.button} ${
        rounded ? styles.rounded : ''
      } ${className}`}
      variantClassName={`${styles[size]} ${styles[style]}`}
      iconLeft={icon}
      iconSize={size === CompactButtonSize.Large ? 20 : 18}
      {...rest}
    />
  );
};
