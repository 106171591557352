import React, { ReactNode, useEffect, useState } from 'react';
import { formatDateTime, secondsToDuration } from '../../../../utils/dateUtil';
import {
  Accordion,
  AccordionSection,
} from '../../../../aurora/components/Accordion/Accordion';
import { Text } from '../../../../aurora/typography/Text/Text';
import {
  JourneyEntity,
  JourneyTypeEnum,
} from '../../../../state/journeys/types';
import styles from './JourneyInfoSlideout.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import IntendedDestination from '../IntendedDestination';
import JourneyStatusBadge from './JourneyStatusBadge';
import { Label } from '../../../../aurora/typography/Label/Label';
import Badge, { BadgeStyle } from '../../../../aurora/components/Badge/Badge';
import { ShipmentTypeEnum } from '../../../../state/shipments/types';
import { IconSnow } from '../../../../aurora/icons';
import { toAddressString } from '../../../../utils/addressUtil';
import { getSelectedLegIndex } from '../../../../utils/journeyUtils';

interface InfoPairProps {
  label: string;
  value?: string | ReactNode;
  artycOnly?: boolean;
}
const InfoPair = ({ label, value }: InfoPairProps) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <div className={styles.infoPair}>
      <Label size="sm" color="strong900" className={styles.label}>
        {label}
      </Label>
      <Text size="sm" color="sub600" className={styles.value}>
        {value}
      </Text>
    </div>
  );
};

interface Props {
  isArtycViewer: boolean;
  journey: JourneyEntity;
}
const JourneyInfoSlideoutContent = ({ isArtycViewer, journey }: Props) => {
  const segment = useSelector((root: RootState) =>
    journey.segmentId !== undefined && journey.segmentId !== null
      ? root.segments.segmentById[journey.segmentId]
      : undefined
  );
  const shipments = useSelector((root: RootState) =>
    journey !== undefined
      ? root.journeys.shipmentsByJourney[journey._id]
      : undefined
  );

  const [openIdx, setOpenIdx] = useState(0);

  useEffect(() => {
    const legIndex = getSelectedLegIndex(journey, shipments);
    setOpenIdx(legIndex);
  }, [shipments, journey]);

  const summaryInfo: InfoPairProps[] = [
    { label: 'Internal ID', value: journey._id, artycOnly: true },
    { label: 'Customer', value: journey.companyName, artycOnly: true },
    { label: 'Journey Type', value: journey.journeyType, artycOnly: true },
    {
      label: 'Assigned Shipper',
      value: journey.serialNumber || 'Awaiting Assignment',
    },
    {
      label: 'Start Date',
      value: formatDateTime(journey.startTime),
    },
    {
      label: 'End Date',
      value: formatDateTime(journey.endTime),
    },
  ];

  const legs: AccordionSection[] = [...(shipments || [])]
    .sort((a, b) => a.leg - b.leg)
    .map((shipment) => {
      const isActiveLeg = shipment.shipmentType === ShipmentTypeEnum.REGULATED;
      const isSegmentLeg =
        segment !== undefined && shipment.segment === segment._id;

      const legInfo: InfoPairProps[] = [];
      legInfo.push({
        label: 'Shipment ID',
        value: shipment.pid,
      });

      // assumes there'd only be one leg for intra-campus
      if (
        journey.journeyType === JourneyTypeEnum.INTRA_CAMPUS &&
        segment !== undefined
      ) {
        legInfo.push({
          label: 'Intended Destination',
          value: <IntendedDestination segment={segment} />,
        });
      }

      if (isSegmentLeg) {
        legInfo.push(
          ...[
            { label: 'Start Date', value: formatDateTime(segment.startTime) },
            { label: 'End Date', value: formatDateTime(segment.endTime) },
            { label: 'Duration', value: secondsToDuration(segment.lengthSec) },
          ]
        );
      } //else {
      // TODO(carrier-tracking): add carrier tracking stuff to slideout
      // legInfo.push(
      //   ...[
      //     { label: 'Start Date', value: formatDateTime(shipment.startTime) },
      //     { label: 'End Date', value: formatDateTime(shipment.endTime) },
      //   ]
      // );
      //}

      if (shipment.origin) {
        legInfo.push({
          label: 'Origin',
          value: toAddressString(shipment.origin),
        });
      }

      if (shipment.destination) {
        legInfo.push({
          label: 'Destination',
          value: toAddressString(shipment.destination),
        });
      }

      return {
        header: (
          <div className={styles.legHeading}>
            <Label size="sm" color="strong900">
              Leg {shipment.leg} of {(shipments || []).length}
            </Label>
            {isActiveLeg ? (
              <Badge
                iconLeft={IconSnow}
                content="Cooling"
                color="blue"
                style={BadgeStyle.Lighter}
              />
            ) : null}
          </div>
        ),
        body: (
          <div className={styles.legInfo}>
            {legInfo
              .filter(
                (pair) => !pair.artycOnly || (pair.artycOnly && isArtycViewer)
              )
              .map((pair, idx) => (
                <InfoPair key={idx} label={pair.label} value={pair.value} />
              ))}
          </div>
        ),
      };
    });

  return (
    <>
      <div className={styles.header}>
        <Label size="xl" color="strong900">
          {journey.pid}
        </Label>
        <JourneyStatusBadge status={journey.status} />
      </div>
      <div className={styles.journeySummary}>
        {summaryInfo
          .filter(
            (pair) => !pair.artycOnly || (pair.artycOnly && isArtycViewer)
          )
          .map((pair, idx) => (
            <InfoPair key={idx} label={pair.label} value={pair.value} />
          ))}
      </div>
      {legs.length > 0 ? (
        <>
          <Label size="sm" color="sub600" className={styles.sectionHeading}>
            Legs
          </Label>
          <Accordion sections={legs} initialOpenIdx={openIdx} />
        </>
      ) : null}
    </>
  );
};

export default JourneyInfoSlideoutContent;
