import React from 'react';
import Slideout from '../../../aurora/components/Slideout/Slideout';
import { Label } from '../../../aurora/typography/Label/Label';
import { PcbTestEntity } from '../../../state/pcbTests/types';
import styles from './PcbTestSlideout.module.scss';

interface Props {
  pcbTest: PcbTestEntity | undefined;
  open: boolean;
  closeSlideout: () => void;
}
const PcbTestSlideout = ({ pcbTest, open, closeSlideout }: Props) => {
  if (pcbTest === undefined) {
    return null;
  }
  if (Object.keys(pcbTest.data).length === 0) {
    return <div>No data</div>;
  }

  const tests = Object.keys(pcbTest.data).filter((key) => key !== 'uart_logs');
  const logs = pcbTest.data['uart_logs'];
  const failureReason = pcbTest.failureReason;

  return (
    <Slideout open={open} onClose={closeSlideout} title="PCB Test">
      <Label size="md" color="strong900">
        Tests
      </Label>
      {tests.map((key) => (
        <div key={key} className={styles.test}>
          <div>{key}</div>
          <div>{pcbTest.data[key]}</div>
        </div>
      ))}
      {failureReason !== undefined && Object.keys(failureReason).length > 0 && (
        <>
          <Label size="md" color="strong900">
            Failure Reason
          </Label>
          {Object.keys(failureReason).map((key) => (
            <div key={key} className={styles.test}>
              <div>{key}</div>
              <div>{failureReason[key]}</div>
            </div>
          ))}
        </>
      )}
      {logs === undefined ? null : (
        <>
          <Label size="md" color="strong900">
            Logs
          </Label>
          <div className={styles.logs}>
            <div>Logs:</div>
            <pre>{logs}</pre>
          </div>
        </>
      )}
    </Slideout>
  );
};

export default PcbTestSlideout;
