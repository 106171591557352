import React, { useEffect, useState } from 'react';
import DevicesApi from '../../../../api/devicesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import JourneysApi from '../../../../api/journeysApi';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import styles from './EditJourneyForm.module.scss';
import LoadingSpinner from '../../../../components/layout/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import { JourneyEntity } from '../../../../state/journeys/types';
import { DeviceEntity } from '../../../../state/devices/types';
import { EditJourneyFormData, journeyToEditFormData } from './mapJourneyData';
import { Form } from 'react-final-form';
import EditJourneyForm from './EditJourneyForm';
import arrayMutators from 'final-form-arrays';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
  onSubmit: (data: EditJourneyFormData) => Promise<any>;
  onClose: () => void;
}

const EditJourneyFormModal = ({
  journey,
  isArtycViewer,
  onSubmit,
  onClose,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [shipments, setShipments] = useState<ShipmentEntity[]>([]);
  const [shippers, setShippers] = useState<DeviceEntity[]>([]);
  const shipperOptions = shippers.map((shipper) => ({
    value: shipper._id,
    label: shipper.serialNumber,
  }));

  useEffect(() => {
    const fetchData = async () => {
      const shipmentsResp = await JourneysApi.getShipmentsForJourney(
        axios,
        auth,
        journey._id
      );
      setShipments(shipmentsResp);

      if (isArtycViewer) {
        const shippersResp = await DevicesApi.getArtycDevices(axios, auth);
        setShippers(shippersResp.entities);
      }

      setHasFetchedData(true);
    };

    fetchData();
  }, []);

  const submit = async (data: EditJourneyFormData) => {
    setIsSubmitting(true);

    try {
      await onSubmit(data);
      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Your changes have been saved successfully.',
      });
      onClose();
    } catch (err: any) {
      showToast({
        type: 'error',
        title: 'Error',
        text:
          err.response?.data?.message ||
          'Something went wrong. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!hasFetchedData) {
    return (
      <div className={`${styles.formContainer} ${styles.spinner}`}>
        <LoadingSpinner width={50} height={50} />
      </div>
    );
  }

  return (
    <Form
      onSubmit={submit}
      mutators={{ ...arrayMutators }}
      initialValues={journeyToEditFormData(journey, shipments)}
      render={({ handleSubmit }) => (
        <div className={styles.modalContainer}>
          <div className={styles.scrollableContent}>
            <EditJourneyForm
              isArtycViewer={isArtycViewer}
              shipperOptions={shipperOptions}
            />
          </div>
          <div className={styles.stickyFooter}>
            <Button
              variant="secondary"
              label="Cancel"
              onClick={onClose}
              disabled={isSubmitting}
            />
            <Button
              variant="primary"
              label="Save"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    />
  );
};

export default EditJourneyFormModal;
