import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { IconDownload } from '../../../../aurora/icons';
import { downloadCsvAction } from '../../../../state/segments';
import { AppDispatch } from '../../../../state/store';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { JourneyEntity } from '../../../../state/journeys/types';
import { Tooltip } from '../../../../aurora/components/Tooltip/Tooltip';

interface Props {
  journeys: JourneyEntity[];
  isArtycViewer: boolean;
}
const JourneysBulkAction = ({ journeys, isArtycViewer }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const hasEmptySegments =
    journeys.filter((journey) => journey.segmentId === undefined).length > 0;

  const downloadSegmentCsv = () => {
    dispatch(
      downloadCsvAction({
        axios: axiosPrivate,
        auth,
        journeys,
        fullLog: false,
        isArtycViewer,
      })
    );
  };

  const downloadButton = (
    <Button
      variant="secondary"
      label="Download Shipments"
      iconLeft={IconDownload}
      sx={{ marginX: '12px' }}
      onClick={downloadSegmentCsv}
    />
  );

  if (hasEmptySegments) {
    return (
      <Tooltip
        label={
          "Some of the shipments don't yet have data, so you won't receive a CSV for every journey"
        }
      >
        {downloadButton}
      </Tooltip>
    );
  }

  return downloadButton;
};

export default JourneysBulkAction;
