import React from 'react';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import { IconPlus } from '../../../aurora/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  customerCostReportInitialState,
  setFormInputs,
} from '../../../state/customerCostReports';

const CreateCostButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Button
      variant="primary"
      label="Create Report"
      iconLeft={IconPlus}
      onClick={() => {
        navigate('/portal/cost-calculator');
        dispatch(setFormInputs(customerCostReportInitialState.formInputs));
      }}
    />
  );
};

export default CreateCostButton;
