import React from 'react';
import {
  ShipmentEntity,
  shippoCarrierDisplayNames,
  shippoTrackingStatusDisplayNames,
  ShippoTrackingStatusEnum,
} from '../../../../../state/shipments/types';
import styles from './CarrierTrackingCard.module.scss';
import ProgressStepper from '../../../../../aurora/components/ProgressStepper/ProgressStepper';
import { format, parseISO } from 'date-fns';
import { createTrackingUrl } from '../../../../../utils/carrierTrackingUtils';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import InfoCard from '../InfoCard';
import { formatDateWithTime } from '../../../../../utils/dateUtil';

interface Props {
  shipment: ShipmentEntity;
}

const CarrierTrackingCard = ({ shipment }: Props) => {
  const { shippoInfo, trackingNumber, carrier } = shipment;

  const stepMap = {
    [ShippoTrackingStatusEnum.PRE_TRANSIT]: 1,
    [ShippoTrackingStatusEnum.TRANSIT]: 2,
    [ShippoTrackingStatusEnum.DELIVERED]: 3,
    [ShippoTrackingStatusEnum.RETURNED]: 0,
    [ShippoTrackingStatusEnum.UNKNOWN]: 0,
  };

  const lastStatus =
    shippoInfo?.tracking_history?.[shippoInfo?.tracking_history.length - 1]
      ?.status;

  const lastStatusDate = lastStatus
    ? new Date(
        shippoInfo?.tracking_history?.[
          shippoInfo?.tracking_history.length - 1
        ]?.status_date
      )
    : undefined;

  const currentStepIndex = shippoInfo
    ? shippoInfo.tracking_history
        .map((history) => stepMap[history.status])
        .reduce(
          (max, current) => Math.max(max, current),
          stepMap[shippoInfo.tracking_status.status]
        )
    : 0;

  const trackingUrl =
    trackingNumber && carrier ? createTrackingUrl(carrier, trackingNumber) : '';

  const trackingSection =
    trackingNumber && carrier ? (
      <>
        <div>
          <Label span size="sm" color="strong900">
            {'Carrier: '}{' '}
          </Label>
          <Text span size="sm" color="sub600">
            {shippoCarrierDisplayNames[carrier]}
          </Text>
        </div>

        <div>
          <Label span size="sm" color="strong900">
            {'Tracking Number: '}
          </Label>
          <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
            <Text span size="sm">
              {trackingNumber}
            </Text>
          </a>
        </div>
      </>
    ) : null;

  const shippoSection = shippoInfo ? (
    <>
      <div>
        <Label span size="sm" color="strong900">
          {'Status: '}
        </Label>
        <Text span size="sm" color="sub600">
          {
            shippoTrackingStatusDisplayNames[
              shippoInfo.tracking_history[
                shippoInfo.tracking_history.length - 1
              ].status
            ]
          }
        </Text>
      </div>

      <div>
        <Label span size="sm" color="strong900">
          {'Current Location: '}
        </Label>
        <Text span size="sm" color="sub600">
          {shippoInfo.tracking_status.location
            ? `${shippoInfo.tracking_status.location.city}, ${shippoInfo.tracking_status.location.state}`
            : '-'}
        </Text>
      </div>

      <div>
        <Label span size="sm" color="strong900">
          {'Estimated Delivery: '}
        </Label>
        <Text span size="sm" color="sub600">
          {shippoInfo.eta
            ? format(parseISO(shippoInfo.eta), 'dd/MM/yyyy')
            : '-'}
        </Text>
      </div>

      <div>
        <Label span size="sm" color="strong900">
          {'Last Updated: '}
        </Label>
        <Text span size="sm" color="sub600">
          {lastStatusDate ? formatDateWithTime(lastStatusDate) : '-'}
        </Text>
      </div>
    </>
  ) : null;

  const nothingSection =
    trackingSection === null && shippoSection === null ? (
      <Text span size="sm" color="sub600">
        No tracking information yet
      </Text>
    ) : null;

  return (
    <InfoCard title="Carrier Tracking">
      <div className={styles.section}>
        <div className={styles.textSection}>
          {trackingSection}
          {shippoSection}
          {nothingSection}
        </div>
      </div>

      <div className={styles.section}>
        <ProgressStepper
          steps={['Pre-Transit', 'In Transit', 'Delivered']}
          currentStepIndex={currentStepIndex}
        />
      </div>
    </InfoCard>
  );
};

export default CarrierTrackingCard;
