import React, { useRef } from 'react';
import { SegmentToSnapshotsMap } from '../../../../../state/segments';
import Map, { MapPointGroup } from '../../../../../components/map/Map';
import { mkPointGroups } from './mapUtils';

export interface SnapshotsMapProps {
  snapshotsBySegment: SegmentToSnapshotsMap;
}

const SnapshotsMap = ({ snapshotsBySegment }: SnapshotsMapProps) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const pointGroups: MapPointGroup[] = mkPointGroups(snapshotsBySegment);

  return <Map pointGroups={pointGroups} ref={mapContainerRef} />;
};

export default SnapshotsMap;
