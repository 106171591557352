import React, { forwardRef, useEffect, useState } from 'react';
import mapboxgl, { LayerSpecification } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { initializeMap, setupMap } from './mapCreation';
import styles from './Map.module.scss';
import { GeoJsonProperties } from 'geojson';

export interface MapPoint {
  latitude: number;
  longitude: number;
  properties: GeoJsonProperties;
}

export interface MapPointGroup {
  groupId: string;
  points: MapPoint[];
  groupStyle: LayerSpecification;
  onHoverHtml?: (properties: GeoJsonProperties) => string;
}

export interface MapProps {
  pointGroups: MapPointGroup[];
}

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const Map = forwardRef(function Map(
  { pointGroups }: MapProps,
  mapContainerRef: React.ForwardedRef<HTMLDivElement>
) {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!mapContainerRef || !('current' in mapContainerRef)) {
      return;
    }

    if (mapContainerRef.current) {
      const { map, popup } = initializeMap(mapContainerRef.current);

      setupMap(map, pointGroups, popup);

      map.on('error', (error) => {
        setError(`Error loading map:', ${JSON.stringify(error)}`);
      });

      return () => {
        map.remove();
      };
    }
  }, [pointGroups]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.mapContainer}>
      <div ref={mapContainerRef} className={styles.map}></div>
    </div>
  );
});

Map.displayName = 'Map';

export default Map;
