import React, { useCallback } from 'react';
import TextInput from '../TextInput/TextInput';
import { IconSearch } from '../../icons';
import styles from './TableSearch.module.scss';
import debounce from 'lodash/debounce';

interface Props {
  onChange: (filter: string) => void;
  placeholder: string;
  debounceTime?: number;
}

const TableSearch = ({ onChange, placeholder, debounceTime = 300 }: Props) => {
  const debouncedOnChange = useCallback(
    debounce((text: string) => {
      onChange(text);
    }, debounceTime),
    [onChange, debounceTime]
  );

  return (
    <TextInput
      className={styles.tableSearch}
      variant="filter"
      placeholder={placeholder}
      iconLeft={IconSearch}
      onChange={debouncedOnChange}
    />
  );
};

export default TableSearch;
