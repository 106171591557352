import React, { RefObject } from 'react';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { EventEntity } from '../../../../../state/events/types';
import EventsSection from '../../../../events/components/slideout/EventsSection';
import EventStatusBadge from '../../EventStatusBadge';
import InfoCard from '../InfoCard';
import styles from './EventInfoCard.module.scss';
interface Props {
  events: EventEntity[];
  eventsCardRef: RefObject<HTMLDivElement>;
  viewEvents: boolean;
  setViewEvents: (value: boolean) => void;
}

const EventInfoCard = ({
  events,
  eventsCardRef,
  viewEvents,
  setViewEvents,
}: Props) => {
  if (events.length === 0) {
    return null;
  }

  const title = (
    <div className={styles.title}>
      <Label size="sm" color="strong900">
        Events
      </Label>
      <EventStatusBadge totalEvents={events.length} />
    </div>
  );

  return (
    <InfoCard
      ref={eventsCardRef}
      title={title}
      accordion
      showOpen={viewEvents}
      onAccordionToggle={(idx, open) => {
        if (!open) {
          setViewEvents(false);
        }
      }}
    >
      <EventsSection events={events} />
    </InfoCard>
  );
};

export default EventInfoCard;
