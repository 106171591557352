import { GeoJsonProperties } from 'geojson';
import { formatGraphTime } from '../../../../../utils/dateUtil';

export const mkOnHoverHtml =
  (segmentId: string) =>
  (properties: GeoJsonProperties): string => {
    if (!properties) {
      return '';
    }

    const indexArray: Array<number> = JSON.parse(properties.indexArray);

    const timestampArray: Array<string> = JSON.parse(properties.timestampArray);

    const timestampRanges = [];
    const indexRanges = [];
    let rangeStart = 0;

    for (let i = 0; i < indexArray.length; i++) {
      if (
        i === indexArray.length - 1 ||
        indexArray[i] + 1 !== indexArray[i + 1]
      ) {
        let timeRangeSubString: string;
        let indexRangeSubString: string;
        if (rangeStart === i) {
          timeRangeSubString = `${formatGraphTime(timestampArray[i])}`;
          indexRangeSubString = `${indexArray[i]}`;
        } else {
          timeRangeSubString = `${formatGraphTime(
            timestampArray[rangeStart]
          )} - ${formatGraphTime(timestampArray[i])}`;
          indexRangeSubString = `${indexArray[rangeStart]} - ${indexArray[i]}`;
        }
        timestampRanges.push(timeRangeSubString);
        indexRanges.push(indexRangeSubString);
        rangeStart++;
      }
    }

    const timeRangeString = timestampRanges.join(', ');
    const indexRangeString = indexRanges.join(', ');

    const tempsArray: Array<{ sleeve: number; evap: number; hs: number }> =
      JSON.parse(properties.tempsArray);

    const tempsRanges = tempsArray.reduce(
      (acc, curr) => {
        acc.minSleeve = Math.min(acc.minSleeve, curr.sleeve);
        acc.maxSleeve = Math.max(acc.maxSleeve, curr.sleeve);
        acc.minHs = Math.min(acc.minHs, curr.hs);
        acc.maxHs = Math.max(acc.maxHs, curr.hs);

        return acc;
      },
      {
        minSleeve: Infinity,
        maxSleeve: -Infinity,
        minHs: Infinity,
        maxHs: -Infinity,
      }
    );

    const formatTemperature = (min: number, max: number) => {
      return min === max ? `${min}` : `${min} - ${max}`;
    };

    const snapshotCount = indexArray.length;

    // the arrays saved in properties of geoJSON features are getting turned into strings
    // (not entirely sure if is mapbox or something else), so we need to unstringify the array
    const popupContent = `
      Segment: ${segmentId}<br>
      Snapshot Count: ${snapshotCount}<br>
      External Temp: ${formatTemperature(
        tempsRanges.minHs,
        tempsRanges.maxHs
      )}<br>
      Internal Temp: ${formatTemperature(
        tempsRanges.minSleeve,
        tempsRanges.maxSleeve
      )}<br>
      Timestamp(s): ${timeRangeString}<br>
      Indices: ${indexRangeString}
    `.trim();

    return popupContent;
  };
