import React from 'react';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { SegmentEntity } from '../../../../state/segments/types';

interface Props {
  segment: SegmentEntity | undefined;
  closeSlideout: () => void;
}
const SlideoutFooter = ({ segment, closeSlideout }: Props) => {
  if (!segment) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        width: '100%',
      }}
    >
      <Button
        variant="secondary"
        label="Close"
        onClick={closeSlideout}
        sx={{ flex: 1 }}
      />
    </div>
  );
};

export default SlideoutFooter;
