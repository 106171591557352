import React, { useState } from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  ColumnsConfig,
  EmptyConfig,
  SlideoutConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { IconCircleQuestion, IconShipment } from '../../aurora/icons';
import PageHeader from '../../components/layout/PageHeader';
import {
  loadJourneys,
  setCurrentPage,
  sortJourneys,
  setCompanyIdsFilter,
  resetPage,
} from '../../state/journeys';
import { useDispatch, useSelector } from 'react-redux';
import { setDateFilter, setDeviceTypesFilter } from '../../state/journeys';
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter/DeviceTypeFilter';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import { RootState } from '../../state/store';
import { isArtyc, selectAuth } from '../../state/auth';
import { formatDate, formatDateTime } from '../../utils/dateUtil';
import { Tooltip } from '../../aurora/components/Tooltip/Tooltip';
import { useSx } from 'dripsy';
import JourneySearch from './components/journeyTable/JourneySearch';
import styles from '../page.module.scss';
import JourneysBulkAction from './components/journeyTable/JourneysBulkAction';
import PageDateFilter from '../../components/filters/PageDateFilter/PageDateFilter';
import MobileCompanyDeviceFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyDeviceFilter';
import JourneyTableAction from './components/journeyTable/JourneyTableAction';
import { JourneyEntity, JourneyStatus } from '../../state/journeys/types';
import JourneyInfoSlideout from './components/journeyTable/JourneyInfoSlideout';
import JourneyStatusBadge from './components/journeyTable/JourneyStatusBadge';
import JourneysPageActions from './components/journeyTable/JourneysPageActions';
import CreateJourneySlideout from './components/journeyForm/CreateJourneySlideout';
import EventStatusBadge from './components/EventStatusBadge';

const JourneysPage = () => {
  const sx = useSx();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const { startDate, endDate } = useSelector(
    (state: RootState) => state.journeys.selectedFilters
  ) || { startDate: undefined, endDate: undefined };
  const parsedStartDate = startDate ? new Date(startDate) : undefined;
  const parsedEndDate = endDate ? new Date(endDate) : undefined;

  const { deviceTypes, companyIds } = useSelector(
    (state: RootState) => state.journeys.selectedFilters || {}
  );

  const viewAsCustomer = useSelector(
    (state: RootState) => state.journeys.viewAsCustomer
  );
  const isArtycViewer = isArtyc(auth) && !viewAsCustomer;

  const [showCreate, setShowCreate] = useState(false);

  const topConfig: TopConfig = {
    additionalBars: [
      <>
        <JourneySearch />
        <div className={styles.filterGroup}>
          <DeviceTypeFilter
            setDeviceTypesAction={setDeviceTypesFilter}
            filteredDeviceTypes={deviceTypes}
          />
          <CompanyFilter
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <MobileCompanyDeviceFilter
            setDeviceTypesAction={(deviceTypes) =>
              dispatch(setDeviceTypesFilter(deviceTypes))
            }
            filteredDeviceTypes={deviceTypes}
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
          <PageDateFilter
            setDateFilters={(startDate, endDate) =>
              dispatch(
                setDateFilter([
                  startDate?.toISOString(),
                  endDate?.toISOString(),
                ])
              )
            }
            clearDateFilters={() =>
              dispatch(setDateFilter([undefined, undefined]))
            }
            filters={[parsedStartDate, parsedEndDate]}
          />
        </div>
      </>,
    ],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconShipment,
    title: 'No journeys found',
    body: "Once journeys are found, they'll show up here",
  };

  // TODO(test): test that company only shows for artyc admin, date is startTime + format
  const columnsConfig: ColumnsConfig<JourneyEntity> = {
    columns: [
      {
        title: 'Journey ID',
        property: 'pid',
      },
      {
        title: 'Serial #',
        property: 'serialNumber',
        showColumn: isArtycViewer,
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtycViewer,
      },
      {
        title: 'P.O Number',
        property: 'poNumber',
        showColumn: !isArtycViewer,
      },
      {
        title: 'Custom ID',
        property: 'customId',
        showColumn: !isArtycViewer,
      },
      {
        title: 'Start Date',
        property: 'startDate',
        customData: (journey: JourneyEntity) => {
          if (journey.startTime) {
            return `${
              journey.isStartTimeEstimated ? 'Est. ' : ''
            }${formatDateTime(journey.startTime)}`;
          }
          if (journey.expectedStartDate) {
            return formatDate(journey.expectedStartDate);
          }

          return '-';
        },
        customComponent(data, _, onClick) {
          if ((data as string).startsWith('Est. ')) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '8px',
                  alignItems: 'center',
                }}
                onClick={onClick}
              >
                <span style={sx({ color: 'neutral800' })}>{data}</span>
                <Tooltip label="We couldn't get a GPS lock to acquire an accurate time for this shipment. The start time is estimated from the upload time.">
                  <IconCircleQuestion
                    color="neutral800"
                    height={16}
                    width={16}
                  />
                </Tooltip>
              </div>
            );
          }
          return data;
        },
      },
      {
        title: 'Phase',
        property: 'status',
        customComponent: (data, journey) => {
          return <JourneyStatusBadge status={journey.status} />;
        },
      },
      {
        title: 'Current Leg',
        property: 'currentLeg',
        customComponent: (data, journey) => {
          if (
            journey.currentLeg === 0 ||
            journey.status === JourneyStatus.COMPLETE
          ) {
            return '-';
          }

          return `${journey.currentLeg} of ${journey.totalLegs}`;
        },
      },
      {
        title: 'Events',
        property: 'totalEvents',
        customComponent: (data, journey) => {
          if (!journey.segmentId) {
            return null;
          }

          return <EventStatusBadge totalEvents={journey.totalEvents} />;
        },
      },
    ],
    actionColumn: (journey: JourneyEntity) => (
      <JourneyTableAction journey={journey} isArtycViewer={isArtycViewer} />
    ),
    bulkAction: (journeys: JourneyEntity[]) => (
      <JourneysBulkAction journeys={journeys} isArtycViewer={isArtycViewer} />
    ),
  };

  const slideoutConfig: SlideoutConfig<JourneyEntity> = {
    slideout: (journey, open, closeSlideout) => (
      <JourneyInfoSlideout
        journey={journey}
        open={journey ? open : false}
        closeSlideout={closeSlideout}
        isArtycViewer={isArtycViewer}
      />
    ),
  };

  return (
    <>
      <PageHeader
        headingText="Journeys"
        action={
          <JourneysPageActions openCreateSlideout={() => setShowCreate(true)} />
        }
      />
      <DataTableContainer
        key="journeys"
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        slideoutConfig={slideoutConfig}
        stateName={'journeys'}
        loadDataAction={loadJourneys}
        sortAction={sortJourneys}
        pageAction={setCurrentPage}
        resetAction={resetPage}
      />
      <CreateJourneySlideout
        open={showCreate}
        closeSlideout={() => setShowCreate(false)}
        isArtycViewer={isArtycViewer}
      />
    </>
  );
};

export default JourneysPage;
