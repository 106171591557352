import React from 'react';
import { SegmentEntity } from '../../../../../state/segments/types';
import { secondsToFullMinutes } from '../../../../../utils/dateUtil';
import { ShippingProfileEntity } from '../../../../../state/shippingProfile/types';
import styles from './TempManagementSection.module.scss';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';

interface SectionProps {
  label: string;
  text: string;
}

const Section = ({ label, text }: SectionProps) => (
  <div>
    <Label size="sm" color="strong900">
      {label}
    </Label>
    <Text size="sm" color="sub600">
      {text}
    </Text>
  </div>
);

const degC = (temp: number): string => `${temp}°C`;
const inMinutes = (minutes: number): string => `${minutes} minutes`;

interface Props {
  segment: SegmentEntity | undefined;
  shippingProfile?: ShippingProfileEntity;
}

const TempManagementSection = ({ segment, shippingProfile }: Props) => {
  if (shippingProfile !== undefined) {
    const {
      name: shippingProfileName,
      minSteadyStateTempC,
      maxSteadyStateTempC,
      samplingRateSec,
      // excursionWindowSec,
      // cooldownTimeoutSec,
      // minCriticalTempC,
      // maxCriticalTempC,
    } = shippingProfile;

    /*
      we aren't fully using shipping profiles yet -- we just read the id hardcoded from shippers
      in theory our sampling rates are aligned, but they could get out of sync
      so we should use the shipper's actual sampling rate and just fallback on shipping profile for now
      once we're actually setting shipping profile on shippers, we can fully use them then
    */
    const samplingRateMinutes =
      segment !== undefined
        ? segment.logIncrement / 60 / 1000
        : secondsToFullMinutes(samplingRateSec);
    return (
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <Section label="Profile" text={shippingProfileName} />
          <Section
            label={'Sampling Rate'}
            text={inMinutes(samplingRateMinutes)}
          />
        </div>
        <div className={styles.contentRow}>
          <Section label={'Min. Temp'} text={degC(minSteadyStateTempC)} />
          <Section label={'Max. Temp'} text={degC(maxSteadyStateTempC)} />
        </div>
        {/* <Section
          label={'Excursion Window'}
          text={inMinutes(secondsToFullMinutes(excursionWindowSec))}
        /> */}
        {/* {(minCriticalTempC !== undefined || maxCriticalTempC !== undefined) && (
        <div className={styles.contentRow}>
          {minCriticalTempC !== undefined && (
            <Section
              label={'Critical Temp. Low'}
              text={degC(minCriticalTempC)}
            />
          )}
          {maxCriticalTempC !== undefined && (
            <Section
              label={'Critical Temp. High'}
              text={degC(maxCriticalTempC)}
            />
          )}
        </div>
      )} */}
        {/* <div className={styles.contentRow}>
        <Section
          label={'Cooldown Timeout'}
          text={inMinutes(secondsToFullMinutes(cooldownTimeoutSec))}
        />
      </div> */}
      </div>
    );
  }

  if (segment !== undefined) {
    return (
      <div className={styles.content}>
        <div className={styles.contentRow}>
          <Section
            label={'Set Temperature'}
            text={degC(segment.setTemperature)}
          />
          <Section
            label={'Sampling Rate'}
            text={inMinutes(segment.logIncrement / 60 / 1000)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <Text size="sm" color="sub600">
        No shipping profile set
      </Text>
    </div>
  );
};

export default TempManagementSection;
