import React, { useEffect } from 'react';
import { Label } from '../../../../aurora/typography/Label/Label';
import SelectField from '../../../../components/forms/SelectField';
import TextField from '../../../../components/forms/TextField';
import { required } from '../../../../components/forms/validators';
import { carrierOptions, states } from './formOptions';
import styles from './AddressSection.module.scss';
import formStyles from '../../../../components/forms/Form.module.scss';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { AddressTypeEnum } from '../../../../state/addresses/types';
import { toAddressString } from '../../../../utils/addressUtil';
import AddressesApi from '../../../../api/addressesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { useField, useForm } from 'react-final-form';
import { setAddresses } from '../../../../state/addresses';
import { createSelector } from '@reduxjs/toolkit';

interface Props {
  heading: string;
  isArtycViewer: boolean;
  isLab?: boolean;
  shipmentPrefix: string;
  addressAccordion?: boolean;
}

const selectLabAddresses = createSelector(
  [(state: RootState) => state.addresses.data],
  (addresses) =>
    addresses.filter((address) => address.addressType === AddressTypeEnum.LAB)
);

const AddressSection = ({
  heading,
  isArtycViewer,
  isLab,
  shipmentPrefix,
  addressAccordion,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();

  const {
    input: { value: company },
  } = useField('company', { subscription: { value: true } });

  const labAddresses = useSelector(selectLabAddresses);
  const form = useForm();

  const resetLabAddresses = () => {
    dispatch(setAddresses({ entities: [], totalPages: 0 }));
  };

  const fetchLabAddresses = async (companyId: string) => {
    resetLabAddresses();
    const result = await AddressesApi.getLabAddresses(axios, auth, companyId);
    dispatch(setAddresses(result));
  };

  // assumes for company users, the company is set in the form
  // lab addresses depend on the company. unset if company changes + fetch new addresses
  const missingCompany = company === '' || company === undefined;
  useEffect(() => {
    if (missingCompany) {
      resetLabAddresses();
      form.change(`${shipmentPrefix}.addressId`, undefined);
    } else if (isLab) {
      // would be more optimal if we only re-fetched if company changed (for artyc users)
      // or if address length is 0 (for all users)
      fetchLabAddresses(company as string);
    }
  }, [company]);

  const labOptions = labAddresses.map((address) => ({
    label: toAddressString(address),
    value: address._id,
  }));

  // if there are existing lab addresses, show a dropdown of them
  const addressFragment =
    isLab && labAddresses.length > 0 ? (
      <SelectField
        label="Lab Name"
        name={`${shipmentPrefix}.addressId`}
        disabled={missingCompany}
        placeholder={missingCompany ? 'Select Company First' : 'Select'}
        options={labOptions}
        validate={required}
      />
    ) : (
      <>
        {isLab ? (
          <>
            <TextField
              label="Lab Name"
              name={`${shipmentPrefix}.addressName`}
              showOptional
            />
            <TextField
              label="Attn"
              name={`${shipmentPrefix}.attn`}
              showOptional
            />
          </>
        ) : null}
        <TextField
          label="Address Line 1"
          name={`${shipmentPrefix}.addressLine1`}
          validate={required}
        />

        <TextField
          label="Address Line 2"
          name={`${shipmentPrefix}.addressLine2`}
          showOptional
        />

        <TextField
          label="City"
          name={`${shipmentPrefix}.city`}
          validate={required}
        />

        <div className={formStyles.formRow}>
          <SelectField
            label="State"
            name={`${shipmentPrefix}.state`}
            options={states}
            validate={required}
          />

          <TextField
            label="Postal Code"
            name={`${shipmentPrefix}.postalCode`}
            validate={required}
          />
        </div>
        <div className={formStyles.formRow}>
          <TextField
            label="Phone Number"
            name={`${shipmentPrefix}.phoneNumber`}
            showOptional
          />
        </div>
      </>
    );

  return (
    <>
      <Label size="sm" className={styles.heading}>
        {heading}
      </Label>

      <div className={formStyles.form}>
        {!addressAccordion ? addressFragment : null}

        {isArtycViewer ? (
          <>
            <div className={formStyles.formRow}>
              <TextField
                label="Tracking Number"
                name={`${shipmentPrefix}.trackingNumber`}
                showOptional
                tooltip="If available, enter the tracking number now. Otherwise, you can add it later"
              />
              <SelectField
                label="Carrier"
                name={`${shipmentPrefix}.carrier`}
                options={carrierOptions}
                showOptional
                clearable
              />
            </div>
          </>
        ) : null}

        {addressAccordion ? (
          <Accordion
            className={styles.addressAccordion}
            sections={[
              {
                header: 'Address Information',
                body: <div className={formStyles.form}>{addressFragment}</div>,
              },
            ]}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddressSection;
