import mapboxgl from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { renderPointGroups } from './mapRendering';

export const initializeMap = (container: HTMLDivElement) => {
  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/light-v11',
    center: [0, 0],
    zoom: 2,
  });

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  return { map, popup };
};

export const setupMap = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup
) => {
  map.on('load', () => {
    const allBounds = renderPointGroups(map, pointGroups, popup);

    if (
      allBounds[0][0] !== Infinity &&
      allBounds[0][1] !== Infinity &&
      allBounds[1][0] !== -Infinity &&
      allBounds[1][1] !== -Infinity
    ) {
      map.fitBounds(allBounds, {
        padding: { top: 20, bottom: 40, left: 20, right: 20 },
      });
    }
  });
};
