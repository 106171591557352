import React, { useState } from 'react';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import { IconPlus } from '../../../aurora/icons';
import DataModal from '../../../components/DataModal';
import UserForm from '../../../components/UserForm';
import UsersApi from '../../../api/usersApi';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../state/auth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const InviteUserButton = () => {
  const auth = useSelector(selectAuth);
  const axiosPrivate = useAxiosPrivate();
  const [showModal, setShowModal] = useState(false);

  const onClose = () => setShowModal(false);

  return (
    <div>
      <Button
        variant="primary"
        label="Invite User"
        iconLeft={IconPlus}
        onClick={() => setShowModal(true)}
      />
      <DataModal title={'Invite User'} show={showModal} onClose={onClose}>
        <UserForm
          onSubmit={(data) => UsersApi.inviteUser(axiosPrivate, auth, data)}
          successMessage="User invited successfully"
          submitText="Invite User"
          onClose={onClose}
        />
      </DataModal>
    </div>
  );
};

export default InviteUserButton;
