import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableNotice from './TableNotice';
import { IconCircleAlert } from '../../aurora/icons';
import { Button } from '../../aurora/components/Button/legacy/Button';

const TableError = () => {
  const navigate = useNavigate();
  const contactSupport = () => {
    navigate('/portal/support');
  };
  const refreshPage = () => {
    window.location.reload();
  };

  const actions = (
    <div style={{ display: 'flex', gap: '12px', justifyContent: 'center' }}>
      <Button
        variant="secondary"
        label="Contact support"
        onClick={contactSupport}
      />
      <Button variant="primary" label="Refresh page" onClick={refreshPage} />
    </div>
  );

  const body =
    'We had trouble loading the page. Please refresh the page or get in touch for support';

  return (
    <TableNotice
      icon={IconCircleAlert}
      iconColors={{
        color: 'error600',
        backgroundColor: 'error50',
      }}
      title={'Something went wrong'}
      body={body}
      actions={actions}
    />
  );
};

export default TableError;
