import { MapPoint, MapPointGroup } from '../../../../../components/map/Map';
import { isValidLatLong } from '../../../../../components/map/mapBounds';
import { SegmentToSnapshotsMap } from '../../../../../state/segments';
import { SegmentSnapshotEntity } from '../../../../../state/segments/types';
import { mkOnHoverHtml } from './mapHandler';

export const groupSnapshotsByPoints = (
  segment: SegmentSnapshotEntity[]
): MapPoint[] => {
  const pointsMap: { [key: string]: MapPoint } = {};
  let currentPointIndex = 0;

  segment.forEach((snapshot, index) => {
    const { location, timestamp, secSinceStart, temps } = snapshot;

    if (location && isValidLatLong(location.lat, location.long)) {
      // TODO: update this logic. It currently groups all snapshots that
      // share a location, but it should only group consecutive snapshots
      // that share a location.
      const coordinates: [number, number] = [location.long, location.lat];
      const coordKey = coordinates.join(',');

      if (!pointsMap[coordKey]) {
        pointsMap[coordKey] = {
          latitude: location.lat,
          longitude: location.long,
          properties: {
            pointIndex: currentPointIndex++,
            timestampArray: [timestamp],
            secSinceStartArray: [secSinceStart],
            tempsArray: [temps],
            indexArray: [index],
          },
        };
      }
      pointsMap[coordKey].properties!.timestampArray.push(timestamp);
      pointsMap[coordKey].properties!.tempsArray.push(temps);
      pointsMap[coordKey].properties!.secSinceStartArray.push(secSinceStart);
      pointsMap[coordKey].properties!.indexArray.push(index);
    }
  });

  return Object.values(pointsMap).sort(
    (a, b) => (a.properties?.pointIndex ?? 0) - (b.properties?.pointIndex ?? 0)
  );
};

export const generateDatasetColors = (datasetIndex: number) => {
  const hueStart = (datasetIndex * 60) % 360;
  const hueEnd = (hueStart + 60) % 360;

  const startColor = `hsl(${hueStart}, 100%, 50%)`;
  const endColor = `hsl(${hueEnd}, 100%, 50%)`;

  return { startColor, endColor };
};

export const mkPointGroups = (
  snapshotsBySegment: SegmentToSnapshotsMap
): MapPointGroup[] => {
  return Object.entries(snapshotsBySegment).map(
    ([segmentId, snapshots], segmentIndex) => {
      const cleanedData = groupSnapshotsByPoints(snapshots);
      const { startColor, endColor } = generateDatasetColors(segmentIndex);
      const groupId = `points${segmentIndex}`;

      const maxPointIndex = Math.max(
        ...cleanedData.map((point) => point.properties?.pointIndex ?? 0)
      );

      return {
        groupId,
        points: cleanedData,
        groupStyle: {
          id: groupId,
          type: 'circle',
          source: groupId,
          paint: {
            'circle-radius': 10,
            'circle-color': [
              'interpolate',
              ['linear'],
              ['get', 'pointIndex'],
              0,
              startColor,
              maxPointIndex,
              endColor,
            ],
          },
        },
        onHoverHtml: mkOnHoverHtml(segmentId),
      };
    }
  );
};
