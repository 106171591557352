import { AddressEntity } from '../state/addresses/types';

export const toAddressString = (address: AddressEntity) => {
  if (address.addressName) {
    return address.addressName;
  }

  const attn = address.attn ? `${address.attn}, ` : '';
  const addressLine2 = address.addressLine2 ? `, ${address.addressLine2}` : '';
  return `${attn}${address.addressLine1}${addressLine2}, ${address.city}, ${address.state} ${address.postalCode}`;
};
