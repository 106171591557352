import { makeTheme } from 'dripsy';

const rootFont = 'Inter, sans-serif';

const baseTheme = makeTheme({
  colors: {
    neutral50: '#FAFBFC',
    neutral100: '#F8F9FB',
    neutral400: '#F5F5F5',
    neutral500: '#EFEFF1',
    neutral700: '#E5E5E7',
    neutral950: '#A5ACBA',
    gray50v1: '#858c95',
    gray100v1: '#596574',
    gray200v1: '#525d6a',
    gray300v1: '#4c5560',
    gray400v1: '#454d56',
    gray600v1: '#383d43',
    gray700v1: '#323539',
    gray900v1: '#252525',
    gray950v1: '#181818',
    primary25: '#ECF5FF',
    primary50: '#D3E7FF',
    primary600: '#0a77ff',
    primary800: '#125DCB',
    primary900: '#1750B1',
    warning600: '#EEA23E',
    success600: '#2EBF43',
    error50: '#FFEFEE',
    error600: '#E33B32',

    // aurora 2.0
    black: '#000',
    white: '#fff',
    gray50: '#F5F7FA',
    gray100: '#F2F5F8',
    gray200: '#E1E4EA',
    gray300: '#CACFD8',
    gray400: '#99A0AE',
    gray500: '#717784',
    gray600: '#525866',
    gray700: '#2B303B',
    gray800: '#222530',
    gray900: '#181B25',
    blue50: '#EFF4FF',
    blue200: '#B2CCFF',
    blue500: '#2970FF',
    blue700: '#004EEB',
    blue900: '#00359E',
    red50: '#FFEBEC',
    red200: '#FFC0C5',
    red500: '#FB3748',
    red900: '#D02533',

    primaryColor: {
      lighter: 'blue50',
      light: 'blue200',
      base: 'blue500',
      dark: 'blue700',
      darker: 'blue900',
    },
    bg: {
      white0: 'white',
      weak50: 'gray50',
      soft200: 'gray200',
      sub300: 'gray300',
      surface800: 'gray800',
      strong900: 'gray900',
    },
    textColor: {
      white0: 'white',
      disabled300: 'gray300',
      soft400: 'gray400',
      sub600: 'gray600',
      strong900: 'gray900',
    },
    border: {
      white0: 'white',
      soft200: 'gray200',
      sub300: 'gray300',
      strong900: 'gray900',
    },
    icon: {
      white0: 'white',
      disabled300: 'gray300',
      soft400: 'gray400',
      sub600: 'gray600',
      strong900: 'gray900',
    },
    faded: {
      lighter: 'gray100',
      light: 'gray200',
      base: 'gray500',
      dark: 'gray800',
    },
    error: {
      lighter: 'red50',
      light: 'red200',
      base: 'red500',
      dark: 'red900',
    },
  },
  text: {
    heading: {
      h1: {
        fontSize: '40px',
        lineHeight: '48px',
        fontWeight: '500',
        letterSpacing: '-1%',
      },
      h2: {
        fontSize: '36px',
        lineHeight: '44px',
        fontWeight: '500',
        letterSpacing: '-1%',
      },
      h3: {
        fontSize: '32px',
        lineHeight: '40px',
        fontWeight: '500',
        letterSpacing: '-1%',
      },
      h4: {
        fontSize: '28px',
        lineHeight: '36px',
        fontWeight: '500',
        letterSpacing: '-0.5%',
      },
      h5: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '500',
        letterSpacing: '0%',
      },
      h6: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: '500',
        letterSpacing: '0%',
      },
    },
    label: {
      xl: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '500',
        letterSpacing: '-1.5%',
      },
      lg: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: '500',
        letterSpacing: '-1.5%',
      },
      md: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500',
        letterSpacing: '-1.1%',
      },
      sm: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '500',
        letterSpacing: '-0.6%',
      },
      xs: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '500',
        letterSpacing: '0%',
      },
    },
    text: {
      xl: {
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '-1.5%',
      },
      lg: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '28px',
        letterSpacing: '-1.5%',
      },
      md: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-1.1%',
      },
      sm: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.6%',
      },
      xs: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0%',
      },
    },
    subheading: {
      md: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '6%',
      },
      sm: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '6%',
      },
      xs: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '4%',
      },
      xxs: {
        fontWeight: '500',
        fontSize: '11px',
        lineHeight: '12px',
        letterSpacing: '2%',
      },
    },
    breadCrumb: {
      fontWeight: '500',
      color: 'gray50v1',
    },
  },
  banner: {
    warning: {
      backgroundColor: 'warning600',
    },
    success: {
      backgroundColor: 'success600',
    },
  },
  buttons: {
    primary: {
      borderColor: 'transparent',
      backgroundColor: 'primary600',
      color: 'white',
      $hover: {
        backgroundColor: 'primary800',
        color: 'white',
      },
      $active: {
        backgroundColor: 'primary800',
        color: 'white',
      },
    },
    secondary: {
      borderColor: 'neutral700',
      backgroundColor: 'white',
      color: 'gray600v1',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray600v1',
      },
      $active: {
        backgroundColor: 'neutral100',
        color: 'gray600v1',
      },
    },
    secondaryError: {
      borderColor: 'error600',
      backgroundColor: 'transparent',
      color: 'error600',
      $hover: {
        backgroundColor: 'error50',
        color: 'error600',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray600v1',
      },
    },
    secondaryNoBorder: {
      borderColor: 'transparent',
      backgroundColor: 'white',
      color: 'gray600v1',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray600v1',
      },
      $active: {
        backgroundColor: 'neutral100',
        color: 'gray600v1',
      },
    },
    ghostPrimary: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'primary600',
      $hover: {
        backgroundColor: 'transparent',
        color: 'primary800',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'primary800',
      },
    },
    ghostSecondary: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray600v1',
      $hover: {
        backgroundColor: 'transparent',
        color: 'gray800v1',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800v1',
      },
    },
    ghostSecondaryHoverBackground: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray600v1',
      $hover: {
        backgroundColor: 'neutral400',
        color: 'gray800v1',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800v1',
      },
    },
    ghostSecondaryGray: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'gray50v1',
      $hover: {
        backgroundColor: 'transparent',
        color: 'gray800v1',
      },
      $active: {
        backgroundColor: 'transparent',
        color: 'gray800v1',
      },
    },
  },
  checkbox: {
    unchecked: {
      backgroundColor: 'white',
      borderColor: 'neutral700',
      $hover: {
        color: 'neutral100',
      },
    },
    checked: {
      borderColor: 'primary600',
      backgroundColor: 'primary600',
      color: 'white',
      $hover: {
        color: 'primary25',
      },
    },
  },
  tooltip: {
    backgroundColor: 'gray600v1',
    color: 'white',
  },
  textInput: {
    filter: {
      outline: 'invert',
      color: 'gray600v1',
      backgroundColor: 'white',
      borderColor: 'neutral700',
      $hover: {
        borderColor: 'neutral700',
      },
      $active: {
        borderColor: 'neutral700',
        boxShadow: 'none',
      },
    },
    form: {
      outline: 'none',
      color: 'gray900',
      backgroundColor: 'white',
      borderColor: 'gray200',
      $hover: {
        borderColor: 'gray300',
      },
      $active: {
        borderColor: 'blue500',
        boxShadow: '0px 0px 0px 3px #B2D5FF',
      },
    },
  },
  fonts: {
    root: rootFont,
  },
  customFonts: {
    [rootFont]: {
      default: rootFont,
    },
  },
  table: {
    borderColor: 'neutral700',
    backgroundColor: 'white',
    th: {
      backgroundColor: 'neutral100',
      color: 'gray50v1',
      $hover: {
        color: 'neutral700',
      },
    },
    td: {
      backgroundColor: 'white',
      color: 'gray50v1',
    },
    bulkActionBar: {
      backgroundColor: 'primary600',
      color: 'white',
    },
  },
  select: {
    form: {
      color: 'gray900',
      fontWeight: '400',
      backgroundColor: 'white',
      borderColor: 'gray200',
      $active: {
        borderColor: 'blue500',
      },
      $placeholder: {
        color: 'gray400',
      },
      $summary: {
        color: 'gray900',
        backgroundColor: 'white',
        fontWeight: '400',
      },
    },
    filter: {
      color: 'gray600v1',
      fontWeight: '500',
      backgroundColor: 'white',
      borderColor: 'neutral500',
      $active: {
        borderColor: 'neutral500',
      },
      $placeholder: {
        color: 'gray600v1',
      },
      $summary: {
        color: 'primary600',
        backgroundColor: 'primary25',
        fontWeight: '500',
        paddingY: '2px',
        paddingX: '10px',
      },
    },
  },
});

export type BaseTheme = typeof baseTheme;
declare module 'dripsy' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DripsyCustomTheme extends BaseTheme {}
}

export default baseTheme;
